import { useContext, useEffect, useState } from 'react';
import { IActiveAuction } from 'interfaces/lending-groups';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, Grid, Link } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { ServiceBlocContext } from 'services/ServiceBlocContext';
import { format } from 'date-fns';
import AuctionBids from 'components/lending-groups/AuctionBids';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React from 'react';
import { UserContext } from 'common/UserContext';
import CardWithTitle from 'components/common/CardWithTitle';

const ActiveAuctions = () => {
  const [activeAuctions, setActiveAuctions] = useState([] as IActiveAuction[]);
  const [lendingGroupId, setLendingGroupId] = useState("");
  const { lendingGroupBloc } = useContext(ServiceBlocContext);
  const [auction, setAuction] = useState({} as IActiveAuction);
  const { tempUserId } = useContext(UserContext);
  const navigate = useNavigate();
  const [showAuctionBids, setShowAuctionBids] = useState(false);

  const renderAuctionBids = (auction: IActiveAuction) => {
    setShowAuctionBids(true);
    setLendingGroupId(auction.lendingGroupId);
    setAuction(auction);
  }
  const renderAuctionActionsMobile = (auction: IActiveAuction) => {
    return (
      <Grid container spacing={1} sx={{ marginTop: '10px' }}>
        <Grid item>
          <Button variant="contained" startIcon={<LocalOfferIcon />}
            onClick={() => renderAuctionBids(auction)}
          >
            Bids
          </Button>
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    let activeAuctionsSubscription = lendingGroupBloc.activeAuctions.subscribe(setActiveAuctions);

    return () => {
      activeAuctionsSubscription.unsubscribe();
    }
  }, [lendingGroupBloc]);

  useEffect(() => {
    if (tempUserId) {
      lendingGroupBloc.getActiveAuctions(tempUserId);
    }
  }, [tempUserId, lendingGroupBloc]);

  const renderRow = (label: string, value: any) => {
    return <Grid container sx={{ paddingBottom: '6px', paddingTop: '6px' }}>
      <Grid item xs={12}>
        <b>{label}: </b>{value}
      </Grid>
    </Grid>
  }

  const selectLendingGroup = (id: string) => {
    navigate(`/lendinggroups/edit/${id}`)
  }

  return (
    <CardWithTitle title='Active Auctions'>
      {!showAuctionBids && activeAuctions.map((auction, index) => (<React.Fragment key={index}><Card key={index} sx={{
        border: '1px solid #e3f2fd',
        marginBottom: '15px'
      }}>
        <CardContent sx={{ padding: '15px' }}>
          <Grid container rowSpacing={1} sx={{ padding: '0 6px' }}>
            {renderRow("Lending Group ID", <Link href="#"
              onClick={() => selectLendingGroup(auction.lendingGroupId)}>
              {auction.lendingGroupDisplayId}
            </Link>)}
            {renderRow("Started At", auction.startedDateTime ?
              format(new Date(auction.startedDateTime.toString()), 'MM/dd/yyyy HH:mm a') : "")}
            {renderRow("Bidded Interest Rate", auction.highestBiddedInterestRate ? auction.highestBiddedInterestRate + "%" : "0%")}
            {renderAuctionActionsMobile(auction)}
          </Grid>
        </CardContent>
      </Card>
      </React.Fragment>))}
      {showAuctionBids && <>
        <Button sx={{ marginBottom: '10px' }} startIcon={<ArrowBackIosIcon />}
          variant="contained" onClick={() => { setShowAuctionBids(false); }}>
          Back
        </Button>
        <AuctionBids lendingGroupId={lendingGroupId} auction={auction} 
        lendingGroupDisplayId={auction.lendingGroupDisplayId} 
        isFromActiveAuctions /></>}
    </CardWithTitle>
  )
}

export default ActiveAuctions;

