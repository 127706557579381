import { NotificationEvent } from "common/Notification";
import { IActiveAuction, IAuction, IAuctionBid, ILender, ILendingGroup, IUser } from "interfaces/lending-groups";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ApiRequest } from "./ApiRequest";
import log from "./LoggingService";
import { MessagingService } from "./MessagingService";


export class LendingGroupBloc {
    private _lendingGroups: BehaviorSubject<ILendingGroup[]>;
    private _lendingGroups$: Observable<ILendingGroup[]>;
    private _userLendingGroups: BehaviorSubject<ILendingGroup[]>;
    private _userLendingGroups$: Observable<ILendingGroup[]>;
    private _lendingGroup: Subject<ILendingGroup>;
    private _lendingGroup$: Observable<ILendingGroup>;
    private _lenders: BehaviorSubject<ILender[]>;
    private _lenders$: Observable<ILender[]>;
    private _searchedLenders: BehaviorSubject<IUser[]>;
    private _searchedLenders$: Observable<IUser[]>;
    private _auctions: BehaviorSubject<IAuction[]>;
    private _auctions$: Observable<IAuction[]>;
    private _activeAuctions: BehaviorSubject<IActiveAuction[]>;
    private _activeAuctions$: Observable<IActiveAuction[]>;
    private _auctionBids: BehaviorSubject<IAuctionBid[]>;
    private _auctionBids$: Observable<IAuctionBid[]>;
    private _messagingService: MessagingService;

    constructor() {
        this._lenders = new BehaviorSubject<ILender[]>([]);
        this._lenders$ = this._lenders.asObservable();
        this._searchedLenders = new BehaviorSubject<IUser[]>([]);
        this._searchedLenders$ = this._searchedLenders.asObservable();
        this._lendingGroups = new BehaviorSubject<ILendingGroup[]>([]);
        this._lendingGroups$ = this._lendingGroups.asObservable();
        this._userLendingGroups = new BehaviorSubject<ILendingGroup[]>([]);
        this._userLendingGroups$ = this._userLendingGroups.asObservable();
        this._lendingGroup = new Subject<ILendingGroup>();
        this._lendingGroup$ = this._lendingGroup.asObservable();
        this._auctions = new BehaviorSubject<IAuction[]>([]);
        this._auctions$ = this._auctions.asObservable();
        this._activeAuctions = new BehaviorSubject<IActiveAuction[]>([]);
        this._activeAuctions$ = this._activeAuctions.asObservable();
        this._auctionBids = new BehaviorSubject<IAuctionBid[]>([]);
        this._auctionBids$ = this._auctionBids.asObservable();
        this._messagingService = MessagingService.getInstance();
        // log("LendingGroupBloc constructed");
    }

    public getAllLendingGroups(): void {
        ApiRequest.getInstance().axios.get<ILendingGroup[]>(`/lendinggroups`)
            .then(r => {
                this._lendingGroups.next(r.data);
            });
    }

    public getUserLendingGroups(userId?: string): void {
        ApiRequest.getInstance().axios.get<ILendingGroup[]>(`users/${userId}/lendinggroups`)
            .then(r => {
                this._userLendingGroups.next(r.data);
            });
    }

    public getLendingGroup(id: string): void {
        ApiRequest.getInstance().axios.get<ILendingGroup>(`/lendinggroups/${id}`)
            .then(r => {
                this._lendingGroup.next(r.data);
            });
    }

    public createLendingGroup(newLendingGroup: ILendingGroup): void {
        log("creating lending group...", newLendingGroup);
        ApiRequest.getInstance().axios.post(`/lendinggroups`, newLendingGroup)
            .then(() => {
                this.getAllLendingGroups();
                this._messagingService.publish(new NotificationEvent({ message: "Lending group created!" }));
            });
    }

    public updateLendingGroup(lendingGroup: ILendingGroup): void {
        log("updating lending group...", lendingGroup);
        ApiRequest.getInstance().axios.put(`/lendinggroups`, lendingGroup)
            .then(() => {
                this.getAllLendingGroups();
                this._messagingService.publish(new NotificationEvent({ message: "Lending group updated!" }));
            });
    }

    public get lendingGroups(): Observable<ILendingGroup[]> {
        return this._lendingGroups$;
    }

    public get userLendingGroups(): Observable<ILendingGroup[]> {
        return this._userLendingGroups$;
    }

    public get lendingGroup(): Observable<ILendingGroup> {
        return this._lendingGroup$;
    }

    public get lenders(): Observable<ILender[]> {
        // observable is exposed instead of BehaviorSubject because .next
        // cannot be called from Observable
        return this._lenders$;
    }

    public get searchedLenders(): Observable<IUser[]> {
        // observable is exposed instead of BehaviorSubject because .next
        // cannot be called from Observable
        return this._searchedLenders$;
    }

    public get auctions(): Observable<IAuction[]> {
        return this._auctions$;
    }

    public get activeAuctions(): Observable<IActiveAuction[]> {
        return this._activeAuctions$;
    }

    public get auctionBids(): Observable<IAuctionBid[]> {
        return this._auctionBids$;
    }

    public addLender(lendingGroupId: string, userId: string, referrerId: string): void {
        console.log("adding lender to lending group...", userId);
        ApiRequest.getInstance().axios.put(`/lendinggroups/${lendingGroupId}/lenders`,
            { userId: userId, referrerId: referrerId }).then(() => {
                this.getLenders(lendingGroupId);
                this._messagingService.publish(new NotificationEvent({ message: "Lender added!" }));
            })
            .catch((error) => {
                log("add lender error: ", error);
                if (error.response && error.response.status === 412) {
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    this._messagingService.publish(new NotificationEvent(
                        { message: error.response.data.message, severity: "error" }));
                }
            });
    }

    public removeLender(lendingGroupId: string, lendingGroupLenderId: number): any {
        console.log("removing lender from lending group. lendingGroupLenderId: ", lendingGroupLenderId);
        ApiRequest.getInstance().axios.delete(`/lendinggroups/${lendingGroupId}/lenders`, {
            data: {
                lendingGroupLenderId: lendingGroupLenderId
            }
        }).then(() => {
            this.getLenders(lendingGroupId);
            this._messagingService.publish(new NotificationEvent({ message: "Lender removed!" }));
        });
    }

    public searchLenders(searchText: string): void {
        ApiRequest.getInstance().axios.get(`/users/search?searchtext=${searchText}`)
            .then(r => {
                this._searchedLenders.next(r.data);
            });
    }

    public getLenders(lendingGroupId: string): void {
        ApiRequest.getInstance().axios.get(`/lendinggroups/${lendingGroupId}/lenders`)
            .then(r => {
                this._lenders.next(r.data);
            });
    }

    public startAuction(lendingGroupId: string) {
        ApiRequest.getInstance().axios.put(`/lendinggroups/${lendingGroupId}/startauction`)
            .then(() => {
                this._messagingService.publish(new NotificationEvent({ message: "Auction started!" }));
                this.getAuctions(lendingGroupId);
            })
            .catch((error) => {
                if (error.response && error.response.status === 412) {
                    this._messagingService.publish(new NotificationEvent(
                        { message: error.response.data.message, severity: "error" }));
                }
            });
    }

    public stopAuction(lendingGroupId: string, lendingGroupAuctionId: number) {
        ApiRequest.getInstance().axios.put(`/lendinggroups/${lendingGroupId}/auctions/${lendingGroupAuctionId}/stop`)
            .then(() => {
                this._messagingService.publish(new NotificationEvent({ message: "Auction ended! Payment calculations in progress!" }));
                this.getAuctions(lendingGroupId);
            })
            .catch((error) => {
                if (error.response && error.response.status === 412) {
                    this._messagingService.publish(new NotificationEvent(
                        { message: error.response.data.message, severity: "error" }));
                }
            });
    }

    public getAuctions(lendingGroupId: string) {
        ApiRequest.getInstance().axios.get(`/lendinggroups/${lendingGroupId}/auctions`)
            .then(r => {
                this._auctions.next(r.data);
            });
    }

    public getActiveAuctions(userId?: string) {
        ApiRequest.getInstance().axios.get(`/users/${userId}/activeauctions`)
            .then(r => {
                this._activeAuctions.next(r.data);
            });
    }

    public getAuctionBids(lendingGroupId: string, lendingGroupAuctionId: number) {
        ApiRequest.getInstance().axios.get(`/lendinggroups/${lendingGroupId}/auctions/${lendingGroupAuctionId}/bids`)
            .then(r => {
                this._auctionBids.next(r.data);
            });
    }

    public addAuctionBid(lendingGroupId: string, lendingGroupAuctionId: number,
        biddedInterestRate: number, biddedUserId?: string) {
        ApiRequest.getInstance().axios.put(`/lendinggroups/${lendingGroupId}/auctions/${lendingGroupAuctionId}/bids`,
            { biddedInterestRate: biddedInterestRate, biddedUserId: biddedUserId })
            .then(() => {
                this._messagingService.publish(new NotificationEvent({ message: "Bidded!" }));
                this.getAuctionBids(lendingGroupId, lendingGroupAuctionId);
            });
    }

    public dispose(): void {
        this._lenders.complete();
        this._auctions.complete();
        this._auctionBids.complete();
    }
}
