import { IconNewSection, IconList, IconDashboard } from '@tabler/icons';
// ==============================|| MENU ITEMS ||============================== //

const dashboardRoutes = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    requiredPermission: 'Permission.LendingGroupRead',
    children: [{
        id: 'default',
        title: 'Dashboard',
        type: 'item',
        url: '/',
        icon: IconDashboard,
        breadcrumbs: false,
        requiredPermission: 'Permission.LendingGroupRead'
    }]
};

const lendingGroupRoutes = {
    id: 'lendingGroups',
    title: 'Lending Groups',
    type: 'group',
    requiredPermission: 'Permission.LendingGroupRead',
    children: [
        {
            id: 'allGroups',
            title: 'All Groups',
            type: 'item',
            url: '/lendinggroups',
            icon: IconList,
            breadcrumbs: true,
            requiredPermission: 'Permission.ViewAllGroups'
        },
        {
            id: 'create',
            title: 'Create Group',
            type: 'item',
            url: '/lendinggroups/new',
            icon: IconNewSection,
            breadcrumbs: false,
            requiredPermission: 'Permission.LendingGroupWrite'
        },
        {
            id: 'myGroups',
            title: 'My Groups',
            type: 'item',
            url: '/users/lendinggroups',
            icon: IconList,
            breadcrumbs: false,
            requiredPermission: 'Permission.LendingGroupRead'
        },
        {
            id: 'loans',
            title: 'My Loans',
            type: 'item',
            url: '/users/loans',
            icon: IconList,
            breadcrumbs: false,
            requiredPermission: 'Permission.LendingGroupRead'
        },
        {
            id: 'activeAuctions',
            title: 'Active Auctions',
            type: 'item',
            url: '/users/activeauctions',
            icon: IconList,
            breadcrumbs: false,
            requiredPermission: 'Permission.LendingGroupRead'
        },
        {
            id: 'transactions',
            title: 'Transactions',
            type: 'item',
            url: '/transactions',
            icon: IconList,
            breadcrumbs: false,
            requiredPermission: 'Permission.LendingGroupRead'
        }
    ]
};

const userRoutes = {
    id: 'users',
    title: 'Users',
    type: 'group',
    requiredPermission: 'Permission.CreateUser',
    children: [
        {
            id: 'allUsers',
            title: 'All Users',
            type: 'item',
            url: '/users',
            icon: IconList,
            breadcrumbs: false,
            requiredPermission: 'Permission.ViewAllUsers'
        },
        {
            id: 'add',
            title: 'Invite User',
            type: 'item',
            url: '/users/new',
            icon: IconNewSection,
            breadcrumbs: false,
            requiredPermission: 'Permission.CreateUser'
        }
    ]
};

const menuItems = {
    items: [dashboardRoutes, lendingGroupRoutes, userRoutes]
};

export default menuItems;