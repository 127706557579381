import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import { Auth0Provider } from '@auth0/auth0-react';
import axios from 'axios';
import { IAppSettings } from 'interfaces/lending-groups';
import { AppContext } from 'common/AppContext';


axios
    .get(`${process.env.PUBLIC_URL}/appSettings.json`)
    .then((res) => {
        let appSettings = res.data.uiSettings as IAppSettings;
        ReactDOM.render(
            <AppContext.Provider value={appSettings}>
                <Auth0Provider
                    domain={appSettings.identityProvider.domain}
                    clientId={appSettings.identityProvider.clientId}
                    audience={appSettings.identityProvider.audience}
                    redirectUri={window.location.origin}>
                    <Provider store={store}>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </Provider>
                </Auth0Provider>
            </AppContext.Provider>,
            document.getElementById('root')
        );
    })
    .catch(err => console.log(err));


// ==============================|| REACT DOM RENDER  ||============================== //



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
