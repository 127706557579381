import { Controller, useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import { IAuction, ILender, ILendingGroup, IUser } from 'interfaces/lending-groups';
import { useParams } from 'react-router-dom';
import LendingGroup from 'components/lending-groups/LendingGroup';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Card, CardContent, debounce, FormControl, Grid, Modal, Tab, Tabs, tabsClasses, TextField, Typography } from '@mui/material';
import log from 'services/LoggingService';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { ServiceBlocContext } from 'services/ServiceBlocContext';
import { format } from 'date-fns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AuctionBids from 'components/lending-groups/AuctionBids';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React from 'react';
import AutocompleteUsers from 'components/common/AutocompleteUsers';
import { UserContext } from 'common/UserContext';
import HasPermission from 'components/common/HasPermission';
import ResetImpersonation from 'components/common/ResetImpersonation';

const EditLendingGroup = () => {
  const [lendingGroup, setLendingGroup] = useState({} as ILendingGroup);
  const [searchedLenders, setSearchedLenders] = useState([] as string[]);
  const [auctions, setAuctions] = useState([] as IAuction[]);
  const [isStartAuctionVisible, setIsStartAuctionVisible] = useState(false);
  const { lendingGroupId } = useParams();
  const [lenders, setLenders] = useState([] as ILender[]);
  const { lendingGroupBloc } = useContext(ServiceBlocContext);
  const [tabIndex, setTabIndex] = useState(0);
  const [showAuctionBids, setShowAuctionBids] = useState(false);
  const [auction, setAuction] = useState({} as IAuction);
  const [searchedUsers, setSearchedUsers] = useState<IUser[]>([] as IUser[]);
  const [userIdToBeAdded, setUserIdToBeAdded] = useState("");
  const [userToBeAdded, setUserToBeAdded] = useState({} as IUser);
  const [referrerIdToBeAdded, setReferrerIdToBeAdded] = useState("");
  const [referrerToBeAdded, setReferrerToBeAdded] = useState({} as IUser);
  const { userBloc } = useContext(ServiceBlocContext);
  const { tempUser, loggedInUserId } = useContext(UserContext);
  const { register, reset, formState: { errors },
    control, setError, clearErrors } = useForm();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
    marginRight: '50px',
    overflow: 'scroll',
    height: '60%',
  };

  const renderRemoveButton = (lendingGroupLenderId: number) => {
    return (
      <Button variant="contained" startIcon={<DeleteIcon />}
        onClick={() => removeLender(lendingGroupLenderId)}>
        Remove
      </Button>
    )
  }

  const renderAuctionBids = (auction: IAuction) => {
    setShowAuctionBids(true);
    setAuction(auction);
  }

  const onUserInputChange = debounce((event, value) => {
    if (value) {
      log("onFromUserInputChange", value);
      userBloc.search(value);
      log("searchedFromUsers: ", searchedUsers);
    }
  }, 1000);

  const onUserChange = (event: any, value: any) => {
    if (value) {
      log("onToUserChange", value);
      setUserIdToBeAdded(value.id);
      setUserToBeAdded(value);
    }
  }

  const onReferrerChange = (value: any) => {
    if (value) {
      log("onReferrerChange", value);
      setReferrerIdToBeAdded(value.id);
      setReferrerToBeAdded(value);
    }
  }

  const renderAuctionActionsMobile = (auction: IAuction) => {
    return (
      <Grid container spacing={1} sx={{ marginTop: '10px' }}>
        <Grid item>
          <Button variant="contained" startIcon={<LocalOfferIcon />}
            onClick={() => renderAuctionBids(auction)}>
            Bids
          </Button>
        </Grid>
        {!auction.endedDateTime && <Grid item>
          <Button variant="contained" startIcon={<CancelIcon />}
            onClick={() => stopAuction(auction.id)}>
            Stop
          </Button>
        </Grid>}
      </Grid>
    )
  }

  useEffect(() => {
    let searchedUsersSubscription = userBloc.searchedUsers.subscribe(result => {
      setSearchedUsers(result);
    });

    return () => {
      searchedUsersSubscription.unsubscribe();
    }
  }, [userBloc]);

  useEffect(() => {
    let lendersSubscription = lendingGroupBloc.lenders.subscribe(setLenders);
    let searchedLendersSubscription = lendingGroupBloc.searchedLenders.subscribe(result => {
      let emails = result.map((s: IUser) => s.email);
      setSearchedLenders(emails)
    });
    let auctionsSubscription = lendingGroupBloc.auctions.subscribe(setAuctions);
    let lendingGroupSubscription = lendingGroupBloc.lendingGroup.subscribe(setLendingGroup);

    return () => {
      lendingGroupSubscription.unsubscribe();
      lendersSubscription.unsubscribe();
      auctionsSubscription.unsubscribe();
      searchedLendersSubscription.unsubscribe();
    }
  }, [lendingGroupBloc]);

  useEffect(() => {
    lendingGroupBloc.getLendingGroup(lendingGroupId);
    // lendingGroupBloc.getLenders(lendingGroupId);
    // lendingGroupBloc.getAuctions(lendingGroupId);
  }, [lendingGroupId, lendingGroupBloc]);

  useEffect(() => {
    reset(lendingGroup);
    if (tempUser) {
      setReferrerToBeAdded(tempUser);
      setReferrerIdToBeAdded(tempUser.id);
    }
  }, [lendingGroup, reset, tempUser]);

  useEffect(() => {
    if (auctions.length === 0 || auctions.every(f => f.endedDateTime !== null)) {
      log("setting auction visible to true");
      setIsStartAuctionVisible(true);
    }
    else {
      log("setting auction visible to false");
      setIsStartAuctionVisible(false)
    }
  }, [auctions])

  const validateInvite = () => {
    log("lender to be added: ", userToBeAdded);
    if (!userToBeAdded || !userIdToBeAdded) {
      setError("userIdToBeAdded", { type: "focus", message: "Choose a user. If not found, invite the user from the menu: Users -> Invite User" }, { shouldFocus: true });
      return;
    }
    else {
      clearErrors("userIdToBeAdded");
    }

    log("referrer to be added: ", referrerToBeAdded);
    if (!referrerToBeAdded || !referrerIdToBeAdded) {
      setError("referrerIdToBeAdded", { type: "focus", message: "Choose a referrer" }, { shouldFocus: true });
      return;
    }
    else {
      clearErrors("referrerIdToBeAdded");
    }

    handleOpen();
  }

  const agreeTermsAndConditions = () => {
    lendingGroupBloc.addLender(lendingGroupId, userIdToBeAdded, referrerIdToBeAdded);
    setUserIdToBeAdded("");
    setUserToBeAdded({} as IUser);
    handleClose();
  }

  const removeLender = (lendingGroupLenderId: number) => {
    lendingGroupBloc.removeLender(lendingGroupId, lendingGroupLenderId);
  }

  const impersonate = (userId: string, firstName: string = "", lastName: string = "",
    lender: ILender) => {
    log("impersonating lender. userId: ", userId);
    log("impersonating lending group. impersonatedLendingGroupDisplayId: ", lendingGroup.displayId);
    userBloc.impersonateUser({
      impersonatedUserId: userId,
      impersonatedUserFirstName: firstName,
      impersonatedUserLastName: lastName,
      impersonatedUser: lender,
      impersonatedLendingGroupDisplayId: lendingGroup.displayId
    });
  }

  const startAuction = () => {
    lendingGroupBloc.startAuction(lendingGroupId);
  }

  const stopAuction = (lendingGroupAuctionId: number) => {
    log("stopping auction. auctionid: ", lendingGroupAuctionId);
    lendingGroupBloc.stopAuction(lendingGroupId, lendingGroupAuctionId);
    // messagingService.publish(new NotificationEvent({ message: "Auction stopped!" }));
  }

  // const isValidEmail = (email: any) =>
  //   // eslint-disable-next-line no-useless-escape
  //   /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
  //     email
  //   );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    if (newValue === 1) {
      lendingGroupBloc.getLenders(lendingGroupId);
    }
    else if (newValue === 2) {
      lendingGroupBloc.getAuctions(lendingGroupId);
    }
  };

  const renderRow = (label: string, value: any) => {
    return <Grid container sx={{ paddingBottom: '6px', paddingTop: '6px' }}>
      <Grid item xs={12}>
        <b>{label}:</b>
      </Grid>
      <Grid item xs={12} >
        {value}
      </Grid>
    </Grid>
  }

  const renderRow2 = (label: string, value: any) => {
    return <>
      <Grid item xs={6}>
        <b>{label}:</b>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        {value}
      </Grid>
    </>
  }

  return (
    <>
      <Tabs value={tabIndex} onChange={handleTabChange}
        variant="scrollable"
        allowScrollButtonsMobile
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}>
        <Tab label="Lending Group Details" id='0' />
        <Tab label="Lenders/Borrowers" id='1' />
        <Tab label="Auction Details" id='2' />
      </Tabs>

      {(tabIndex === 0) &&
        <><br />
          <Card>
            <CardContent>
              <LendingGroup lendingGroup={lendingGroup} />
            </CardContent>
          </Card>
        </>
      }

      {(tabIndex === 1) &&
        <><br />
          <Card>
            <CardContent>
              <Card sx={{ marginBottom: '10px', border: '1px solid #e3f2fd' }}>
                <CardContent sx={{ padding: '20px', '&:last-child': { paddingBottom: '5px' } }}>
                  <Grid container spacing={2} rowSpacing={2} sx={{ padding: '10px 0px' }}>
                    <Grid item md={12} xs={12}>
                      <Typography variant="h4"># of lenders/borrowers: {lenders.length}</Typography>
                      <br />
                      <ResetImpersonation />
                    </Grid>
                    {!lendingGroup.hasAuctionStarted && <HasPermission permission='Permission.AddLender'>
                      <Grid item md={12} xs={12}>
                        <Controller
                          control={control}
                          name="userIdToBeAdded"
                          render={({ field }) =>
                            <FormControl fullWidth>
                              <Autocomplete
                                fullWidth={true}
                                freeSolo={true}
                                autoComplete={true}
                                onInputChange={onUserInputChange}
                                onChange={onUserChange}
                                value={userToBeAdded}
                                getOptionLabel={(s) => s != null && (s as IUser).displayId ? `${(s as IUser).displayId} | ${(s as IUser).firstName} ${(s as IUser).lastName}` : ""}
                                options={searchedUsers}
                                noOptionsText="User not found"
                                renderInput={(params) => <TextField {...params}
                                  variant="standard"
                                  {...register(field.name, { required: { value: true, message: "This is required" } })}
                                  error={errors && errors[field.name]!} helperText={errors[field.name] && errors[field.name].message}
                                  label="Lender (search by name or ID)" />} />
                            </FormControl>}
                        />
                      </Grid>
                      <HasPermission permission='Permission.AssignReferrer'>
                        <Grid item md={12} xs={12}>
                          <Controller
                            control={control}
                            name="referrerIdToBeAdded"
                            render={({ field }) =>
                              <FormControl fullWidth>
                                <AutocompleteUsers
                                  {...register(field.name, { required: { value: true, message: "This is required" } })}
                                  label="Referrer (search by name or ID)"
                                  onChange={onReferrerChange}
                                  initialValue={referrerToBeAdded}
                                  error={errors && errors[field.name]!}
                                  helperText={errors[field.name] && errors[field.name].message}
                                />
                              </FormControl>}
                          />
                        </Grid>
                      </HasPermission>
                      <Grid item md={12} xs={12}>
                        <Button variant="contained" onClick={validateInvite}>Invite</Button>
                      </Grid>
                    </HasPermission>}
                  </Grid>
                </CardContent>
              </Card>
              <br />
              {lenders.map((lender, index) => (<React.Fragment key={index}><Card key={index} sx={{ marginBottom: '10px', border: '1px solid #e3f2fd' }}>
                <CardContent sx={{ padding: '20px', '&:last-child': { paddingBottom: '5px' } }}>
                  {/* {renderRow("First Name", lender.firstName)}
                  {renderRow("Last Name", lender.lastName)} */}
                  {renderRow("Lender/borrower", `${lender.firstName} ${lender.lastName}`)}
                  {/* {renderRow("Email", lender.email)} */}
                  {renderRow("Referrer", `${lender.referrerFirstName} ${lender.referrerLastName}`)}
                  {!lendingGroup.hasAuctionStarted && <HasPermission permission='Permission.RemoveLender'>
                    <Grid item xs={12} sx={{ margin: '10px 0' }}>
                      {renderRemoveButton(lender.lendingGroupLenderId)}
                    </Grid>
                  </HasPermission>}
                  {<HasPermission permission='Permission.ImpersonateLenders'>
                    {lendingGroup.ownerId === loggedInUserId && <Grid item xs={12} sx={{ margin: '10px 0' }}>
                      {
                        <Button variant="contained" startIcon={<DeleteIcon />}
                          onClick={() => impersonate(lender.userId, lender.firstName, lender.lastName, lender)}>
                          Impersonate
                        </Button>
                      }
                    </Grid>}
                  </HasPermission>}
                </CardContent>
              </Card>
              </React.Fragment>))
              }
            </CardContent>
          </Card>

        </>
      }
      {(tabIndex === 2) &&
        <>
          <br />
          {!showAuctionBids && <>
            <Grid container spacing={2} rowSpacing={2}>
              {isStartAuctionVisible && <Grid item md={12}>
                <Button variant="contained" onClick={startAuction} sx={{ marginBottom: '10px' }}>Start Auction</Button>
              </Grid>
              }
            </Grid>
            <Card sx={{
              border: '1px solid #e3f2fd', marginBottom: '10px'
            }}>
              <CardContent>
                <b># of auction(s): {auctions.length}</b>
              </CardContent>
            </Card>
            {auctions.map((auction) => (<>
              <Accordion defaultExpanded={false} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ fontSize: 20 }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ padding: '0 20px' }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      {auction.startedDateTime ? format(new Date(auction.startedDateTime.toString()), 'MM/dd/yyyy') : ""}
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                      {auction.highestBiddedInterestRate}%
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={1} sx={{ padding: '0 6px' }}>
                    {renderRow2("Started At", auction.startedDateTime ?
                      format(new Date(auction.startedDateTime.toString()), 'MM/dd/yyyy HH:mm a') : "")}
                    {renderRow2("Ended At", auction.endedDateTime ?
                      format(new Date(auction.endedDateTime.toString()), 'MM/dd/yyyy HH:mm a') : "")}
                    {renderRow2("Bidded Interest Rate", auction.highestBiddedInterestRate ? auction.highestBiddedInterestRate + "%" : "")}
                    {renderRow("Bidder", auction.highestBidderFirstName ? auction.highestBidderFirstName + " " + auction.highestBidderLastName : "")}
                    {renderAuctionActionsMobile(auction)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>))}</>
          }
          {showAuctionBids && <>
            <Button sx={{ marginBottom: '10px' }} startIcon={<ArrowBackIosIcon />} variant="contained" onClick={() => { setShowAuctionBids(false); }}>
              Back
            </Button>
            <AuctionBids lendingGroupId={lendingGroup.id} auction={auction}
              lendingGroupDisplayId={lendingGroup.displayId} /></>}
          {/* {renderAuctionBidsBackButton()} */}
        </>
      }

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Terms & Conditions
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

            <br />
            Test
          </Typography>
          <br />
          <Button variant="contained" onClick={agreeTermsAndConditions}>Agree</Button>
        </Box>
      </Modal>

    </>
  )
}

export default EditLendingGroup;

 