import CardContent from '@mui/material/CardContent';
import { Alert, Button, Card, Grid, Link, Tab, Tabs, tabsClasses, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import { ILoan } from '../../interfaces/lending-groups';
import { useNavigate } from 'react-router-dom';
import { ServiceBlocContext } from 'services/ServiceBlocContext';
import InfoIcon from '@mui/icons-material/Info';
import { UserContext } from 'common/UserContext';
import log from 'services/LoggingService';

const Loans = () => {
  const [borrowedLoans, setBorrowedLoans] = useState([] as ILoan[]);
  const [lentLoans, setLentLoans] = useState([] as ILoan[]);
  const { userBloc } = useContext(ServiceBlocContext);
  const navigate = useNavigate();
  const { tempUserId } = useContext(UserContext);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    let borrowedLoansSubscription = userBloc.borrowedLoans.subscribe(setBorrowedLoans);
    let lentLoansSubscription = userBloc.lentLoans.subscribe(setLentLoans);

    return () => {
      borrowedLoansSubscription.unsubscribe();
      lentLoansSubscription.unsubscribe();
    }
  }, [userBloc]);

  useEffect(() => {
    if (tempUserId) {
      userBloc.getBorrowedLoans(tempUserId);
    }
  }, [tempUserId, userBloc])

  const selectLendingGroup = (id: string) => {
    navigate(`/lendinggroups/edit/${id}`)
  }

  const renderRow = (label: string, value: any) => {
    return <>
      <Grid item xs={7}>
        <b>{label}:</b>
      </Grid>
      <Grid item xs={5} sx={{ textAlign: "right" }}>
        {value}
      </Grid>
    </>
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number = 0) => {
    setTabIndex(newValue);
    log("tab index: ", newValue);
    if (newValue === 0 && tempUserId) {
      userBloc.getBorrowedLoans(tempUserId);
    }
    else if (newValue === 1 && tempUserId) {
      userBloc.getLentLoans(tempUserId);
    }
  };

  return (
    <>
      <Tabs value={tabIndex} onChange={handleTabChange}
        variant="scrollable"
        allowScrollButtonsMobile
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}>
        <Tab label="Borrowed Loans" id='0' />
        <Tab label="Lent Loans" id='1' />
      </Tabs>


      {(tabIndex === 0) &&
        <><br />
          <Card>
            <CardContent>
              <b># of loan(s) borrowed: {borrowedLoans.length}</b>
            </CardContent>
          </Card>
          <br />
          {borrowedLoans.map((borrowedLoan, index) => (<React.Fragment key={index}><Card key={index}>
            <CardContent>
              <Grid container rowSpacing={1}>
                {renderRow("ID", borrowedLoan.displayId)}
                {renderRow("Lending Group ID", <Link href="#"
                  onClick={() => selectLendingGroup(borrowedLoan.lendingGroupId)}>
                  {borrowedLoan.lendingGroupDisplayId}
                </Link>)}
                {renderRow("Term in Months", borrowedLoan.termInMonths)}
                {renderRow("Loan Amount", borrowedLoan.loanAmount)}
                {renderRow("Current Balance Amount", borrowedLoan.currentBalanceAmount)}
                {renderRow("Interest Rate", borrowedLoan.interestRate)}
                <Grid item xs={12}>
                  <Button variant="contained" startIcon={<InfoIcon />}
                    onClick={() => { navigate(`/loans/${borrowedLoan.id}`) }}>
                    Details
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
            <br />
          </React.Fragment>))
          }
        </>
      }

      {(tabIndex === 1) &&
        <><br />
          {/* <Alert icon={false} severity="success">{lentLoans.length} loan(s) lent</Alert> */}
          <Card>
            <CardContent>
              <b># of loan(s) lent: {lentLoans.length}</b>
            </CardContent>
          </Card>
          <br />
          {lentLoans.map((lentLoan, index) => (<React.Fragment key={index}><Card key={index}>
            <CardContent>
              <Grid container rowSpacing={1}>
                {renderRow("ID", lentLoan.displayId)}
                {renderRow("Lending Group ID", <Link href="#"
                  onClick={() => selectLendingGroup(lentLoan.lendingGroupId)}>
                  {lentLoan.lendingGroupDisplayId}
                </Link>)}
                {renderRow("Term in Months", lentLoan.termInMonths)}
                {renderRow("Loan Amount", lentLoan.loanAmount)}
                {renderRow("Current Balance Amount", lentLoan.currentBalanceAmount)}
                {renderRow("Interest Rate", lentLoan.interestRate)}
                <Grid item xs={12}>
                  <Button variant="contained" startIcon={<InfoIcon />}
                    onClick={() => { navigate(`/loans/${lentLoan.id}`) }}>
                    Details
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
            <br />
          </React.Fragment>))
          }
        </>
      }
    </>
  )
}

export default Loans;