import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const ApplicationError = () => {
    const [searchParams] = useSearchParams();
    const [errorDescription, setErrorDescription] = useState("Error occurred");

    useEffect(() => {
        let errorDescription = searchParams.get("description");
        if (errorDescription)
            setErrorDescription(errorDescription);
    }, [searchParams])

    return (
        <div>
            {errorDescription}
        </div>
    );
};

export default ApplicationError;