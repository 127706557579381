import { Card, CardContent, CardHeader, Typography } from '@mui/material';

interface IProps {
    title: string,
    children: React.ReactNode
}

const CardWithTitle = (props: IProps) => {

    return (
        <Card>
            <CardHeader sx={{padding: '20px 15px 0 15px'}} title={<Typography variant="h3">{props.title}</Typography>} />
            <CardContent sx={{padding: '20px 15px 0px 15px'}}>
                {props.children}
            </CardContent>
        </Card>
    );
};

export default CardWithTitle;
