import { IUser } from "interfaces/lending-groups";
import { BehaviorSubject, Observable } from "rxjs";

export class AppBloc {
    private _loggedInUser: BehaviorSubject<IUser>;
    private _loggedInUser$: Observable<IUser>;

    constructor() {
        this._loggedInUser = new BehaviorSubject<IUser>({} as IUser);
        this._loggedInUser$ = this._loggedInUser.asObservable();
    }

    public get loggedInUser(): Observable<IUser> {
        // observable is exposed instead of BehaviorSubject because .next
        // cannot be called from Observable
        return this._loggedInUser$;
    }

    public setLoggedInUser(loggedInUser: IUser): void {
        console.log("setting loggedin user...", loggedInUser);
        this._loggedInUser.next(loggedInUser);
    }

    public dispose(): void {
        this._loggedInUser.complete();
    }
}
