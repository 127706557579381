import { Card, CardContent, InputAdornment, TextField, Button, Grid, Link, Typography } from "@mui/material";
import { UserContext } from "common/UserContext";
import { format } from "date-fns";
import { IAuctionBid } from "interfaces/lending-groups";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import log from "services/LoggingService";
import { ServiceBlocContext } from "services/ServiceBlocContext";
import Breadcrumbs from "ui-component/extended/Breadcrumbs";

const AuctionBids = () => {
    const { auctionId, lendingGroupId } = useParams();
    const { lendingGroupBloc } = useContext(ServiceBlocContext);
    const [auctionBids, setAuctionBids] = useState([] as IAuctionBid[]);
    const [biddedInterestRate, setBiddedInterestRate] = useState(0.0);
    const { tempUserId } = useContext(UserContext);

    useEffect(() => {
        let auctionBidsSubscription = lendingGroupBloc.auctionBids.subscribe(setAuctionBids);

        return () => {
            auctionBidsSubscription.unsubscribe();
        }
    }, [lendingGroupBloc]);

    useEffect(() => {
        log("id and lendingGroupId", auctionId, lendingGroupId);
        lendingGroupBloc.getAuctionBids(lendingGroupId, +auctionId);
    }, [lendingGroupBloc, lendingGroupId, auctionId]);

    const handleInterestRateChange = (event: any) => {
        setBiddedInterestRate(event.target.value);
    }

    const addBid = () => {
        log("adding bid for tempUserId: ", tempUserId);
        lendingGroupBloc.addAuctionBid(lendingGroupId, +auctionId, biddedInterestRate, tempUserId);
        setBiddedInterestRate(0.0);
    }

    const renderRow = (label: string, value: any) => {
        return <Grid container sx={{ paddingBottom: '3px' }}>
            <Grid item xs={12}>
                <b>{label}:</b>
            </Grid>
            <Grid item xs={12} >
                {value}
            </Grid>
        </Grid>
    }


    return (
        <>
            <Card>
                <CardContent>

                    <Grid container spacing={2} rowSpacing={2}>
                        <Grid item md={11} xs={9}>
                            <TextField
                                fullWidth={true}
                                onChange={handleInterestRateChange}
                                defaultValue={biddedInterestRate}
                                type="number"
                                label="Interest Rate"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">%</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item md={1} xs={3}>
                            <Button variant="contained" onClick={addBid}>Bid</Button>
                        </Grid>
                        <Grid item>
                            <Breadcrumbs aria-label="breadcrumb" card={undefined}
                                divider={undefined} icon={undefined} icons={undefined}
                                maxItems={4} navigation={undefined}
                                rightAlign={undefined} separator=">" title={undefined}
                                titleBottom={undefined} sx={{ color: "red", padding: '12px' }}>
                                <Link underline="hover" color="inherit" href="/">
                                    MUI
                                </Link>
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    href="/material-ui/getting-started/installation/"
                                >
                                    Core
                                </Link>
                                <Typography color="text.primary">Breadcrumbs</Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <br />
            {
                <>
                    {auctionBids.map(auctionBid => (<><Card><CardContent>
                        {renderRow("Name", auctionBid.firstName + " " + auctionBid.lastName)}
                        {renderRow("Bidded Interest Rate", auctionBid.biddedInterestRate)}
                        {renderRow("Bidded Date", format(new Date(auctionBid.biddedDateTime), 'MM/dd/yyyy HH:mm a'))}
                    </CardContent >
                    </Card ><br /></>))}
                </>
            }
        </>
    )
}

export default AuctionBids;