import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, MenuItem, Select, Stack, TextField } from "@mui/material";
import { Controller, useForm } from 'react-hook-form';
import { IUser } from 'interfaces/lending-groups';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { ServiceBlocContext } from 'services/ServiceBlocContext';
import { UserContext } from "common/UserContext";
import log from "services/LoggingService";
import HasPermission from "components/common/HasPermission";

interface IProps {
    user?: IUser
}

const User = (props: IProps) => {
    const { register, handleSubmit, reset, formState: { errors },
        control } = useForm();
    const navigate = useNavigate();
    const { userBloc } = useContext(ServiceBlocContext);
    const { tempUser } = useContext(UserContext);

    useEffect(() => {
        log("tempUser: ", tempUser);
        if (props.user)
            reset(props.user);
    }, [props.user, reset]);

    const cancel = () => {
        navigate('/users');
    }

    const save = (data: any) => {
        if (props.user) {
            console.log("saving ", data);
            userBloc.updateUser(data);
            navigate('/users');
        }
        else {
            console.log("creating ", data);
            userBloc.addUser(data);
            navigate('/users');
        }
    }

    return (
        <form onSubmit={handleSubmit(save)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="firstName"
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <TextField label="First Name" variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register(field.name, { required: { value: true, message: "This is required" } })}
                                    error={errors && errors[field.name]!} helperText={errors[field.name] && errors[field.name].message} />
                            </FormControl>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="lastName"
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <TextField
                                    label="Last Name"
                                    variant="standard"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    {...register(field.name, { required: { value: true, message: "This is required" } })}
                                    error={errors[field.name]} helperText={errors[field.name] && errors[field.name]!.message} />
                            </FormControl>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="email"
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <TextField
                                    label="Email"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    {...register(field.name, { required: { value: true, message: "This is required" } })}
                                    error={errors[field.name]}
                                    helperText={errors[field.name] && errors[field.name].message} />
                            </FormControl>}
                    />
                </Grid>
                <HasPermission permission="Permission.AssignRoleToUser">
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="role"
                            render={({ field }) =>
                                <FormControl fullWidth>
                                    <Select 
                                        label="Role"
                                        variant="standard"
                                        {...register(field.name)}
                                        >
                                        <MenuItem value="SuperAdmin">Super Admin</MenuItem>
                                        <MenuItem value="LendingGroupAdmin">Lending Group Admin</MenuItem>
                                        <MenuItem value="LendingGroupLender">Lending Group Lender</MenuItem>
                                    </Select>
                                </FormControl>}
                        />
                    </Grid></HasPermission>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="isActive"
                        render={({ field }) =>
                            <FormControlLabel control={<Checkbox {...field}
                                checked={field.value === true || field.value === "true" || !props.user ? true : false}
                                onChange={(e) => field.onChange(e.target.checked)} />} label="Active" />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} justifyContent={'flex-end'}>
                        <Button variant="contained" color="error" onClick={cancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit">Save</Button>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    )
}

export default User;