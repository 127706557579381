import { Card, CardContent } from '@mui/material';
import User from 'components/users/User';
import { IUser } from 'interfaces/lending-groups';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ServiceBlocContext } from 'services/ServiceBlocContext';


const EditUser = () => {
  const { userBloc } = useContext(ServiceBlocContext);
  const [user, setUser] = useState({} as IUser);
  const { userId } = useParams();


  useEffect(() => {
    let usersSubscription = userBloc.user.subscribe(setUser);
    return () => {
      usersSubscription.unsubscribe();
    }
  }, [userBloc]);

  useEffect(() => {
    userBloc.getUser(userId);
  }, [userBloc, userId]);


  return (
    <Card>
      <CardContent>
        <User user={user} />
      </CardContent>
    </Card>
  )
}

export default EditUser;