import { IUser } from 'interfaces/lending-groups';
import { useContext, useEffect, useState } from 'react';
import { ServiceBlocContext } from 'services/ServiceBlocContext';
import UserReadOnly from 'components/users/UserReadOnly';
import { Box, Button, Card, CardContent, InputAdornment, OutlinedInput, styled, TextField } from '@mui/material';
import log from 'services/LoggingService';
import React from 'react';
import CardWithTitle from 'components/common/CardWithTitle';
import { UserContext } from 'common/UserContext';
import { IconSearch } from '@tabler/icons';
import { shouldForwardProp } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import ResetImpersonation from 'components/common/ResetImpersonation';

const Users = () => {
  const { userBloc } = useContext(ServiceBlocContext);
  const [users, setUsers] = useState([] as IUser[]);
  const [searchText, setSearchText] = useState("");
  const theme = useTheme();
  const { impersonatedUserId, impersonatedUserFirstName, impersonatedUserLastName } = useContext(UserContext);

  const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
      background: 'transparent !important',
      paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
      width: 250
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 4,
      background: '#fff'
    }
  }));


  const onUserSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    log("onFromUserInputChange", value);
    setSearchText(value);
    if (value) {
      userBloc.search(value);
      // log("searchedFromUsers: ", searchedUsers);
    }
  }

  useEffect(() => {
    let usersSubscription = userBloc.users.subscribe(setUsers);
    let searchedUsersSubscription = userBloc.searchedUsers.subscribe(result => {
      setUsers(result);
    });

    return () => {
      usersSubscription.unsubscribe();
      searchedUsersSubscription.unsubscribe();
    }
  }, [userBloc]);

  useEffect(() => {
    userBloc.getUsers();
  }, [userBloc]);

  const resetImpersonation = () => {
    log("resetting impersonation...");
    userBloc.resetImpersonation();
  }

  return (
    <CardWithTitle title='Users'>
      {/* {impersonatedUserId && <><b>Impersonated: </b>{impersonatedUserFirstName + " " + impersonatedUserLastName}
        <br /> <Button sx={{ marginBottom: '10px', marginTop: '5px' }} variant="contained" onClick={() => resetImpersonation()}>
          Reset Impersonation
        </Button>
        <br /></>} */}

      <ResetImpersonation />
      <Card sx={{
        border: '1px solid #e3f2fd'
      }}>
        <CardContent>
          <Box sx={{ display: { xs: 'none', md: 'block' }, marginBottom: '10px' }}>
            <TextField
              sx={{ width: '100%' }}
              id="input-search-header"
              value={searchText}
              onChange={onUserSearchInputChange}
              placeholder="Search"
              InputProps={{
                endAdornment:
                  <InputAdornment position="start">
                    <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                  </InputAdornment>

              }}

              // endAdornment={
              //   <InputAdornment position="end">
              //     <ButtonBase sx={{ borderRadius: '12px' }}>
              //       <HeaderAvatarStyle variant="rounded">
              //         <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
              //       </HeaderAvatarStyle>
              //     </ButtonBase>
              //   </InputAdornment>
              // }
              aria-describedby="search-helper-text"
            />
          </Box>
          <b># of user(s): {users.length}</b>
        </CardContent>
      </Card>
      <br />
      {
        users.map((user, index) => (<React.Fragment key={index}>
          <UserReadOnly key={index} user={user} displayActionButtons /> <br /></React.Fragment>))
      }
    </CardWithTitle>
  )
}

export default Users;