import { filter, map, Observable, Subject } from "rxjs";

export interface IMessage {
    channel: string;
    data: any;
}

export class MessagingService {
    private _message$: Subject<IMessage>;
    private static _myInstance: MessagingService;

    private constructor() {
        this._message$ = new Subject<IMessage>();
        // console.log("MessagingService ctor...");
    }

    static getInstance() {
        if (!MessagingService._myInstance) {
            // console.log("about to create a new instance of MessagingService...");
            MessagingService._myInstance = new MessagingService();
        }

        // console.log("MessagingService: ", MessagingService._myInstance);
        return MessagingService._myInstance;
    }

    public publish<T>(message: T): void {
        console.log("publishing: ", message);
        const channel = ((message as any).constructor).name;
        this._message$.next({ channel: channel, data: message });
    }

    public of<T>(messageType: { new(...args: any[]): T }): Observable<T> {
        const channel = (messageType as any).name;
        return this._message$.pipe(
            filter((m: IMessage) => m.channel === channel),
            map((m: IMessage) => m.data)
        );
    }
}