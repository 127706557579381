// material-ui

// assets
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import log from 'services/LoggingService';

// ==============================|| PROFILE MENU ||============================== //

const Login = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        log("is logged in: ", isAuthenticated);
        log(location.state);
        let redirectUrl = "";
        if (location.state) {
            redirectUrl = (location.state as any).from;
            if (redirectUrl === "/") {
                sessionStorage.setItem('redirectUrl', "/dashboard");
                log("setting redirectUrl: ", "/dashboard");
            }
            else {
                log("setting redirectUrl: ", redirectUrl);
                sessionStorage.setItem('redirectUrl', redirectUrl);
            }
        }

        if (!isAuthenticated) {
            log("redirecting to idp...");
            const loginAsync = async () => {
                await loginWithRedirect();
            }
            loginAsync().catch(log);
        }
    }, [isAuthenticated, loginWithRedirect, location.state]);

    return (
        <>
        </>
    );
};

export default Login;
