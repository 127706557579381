
// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { IUser } from 'interfaces/lending-groups';
import log from 'services/LoggingService';
import { IMenuItem, ISubMenuItem } from 'interfaces/menu-item-interface';

interface IProps {
    item: IMenuItem,
    user: IUser
}

const NavGroup = (props: IProps) => {
    const theme = useTheme();
    const { item, user } = props;

    // menu list collapse & items
    const items = item.children?.map((menu: ISubMenuItem) => {
        // log("NavGroup menu: ", menu);
        if (user.permissions?.includes(menu.requiredPermission)) {
            switch (menu.type) {
                case 'collapse':
                    return <NavCollapse key={menu.id} menu={menu} level={1} />;
                case 'item':
                    return <NavItem key={menu.id} item={menu} level={1} />;
                default:
                    return (
                        <Typography key={menu.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        }
    });

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Typography variant="caption" sx={{ ...(theme.typography as any).menuCaption }} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...(theme.typography as any).subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

export default NavGroup;
