import CardContent from '@mui/material/CardContent';
import { Button, Card, Grid, Link } from "@mui/material";
import { IUser } from 'interfaces/lending-groups';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import log from 'services/LoggingService';
import { useContext } from 'react';
import { ServiceBlocContext } from 'services/ServiceBlocContext';

interface IProps {
    user: IUser
    displayActionButtons?: boolean
}

const UserReadOnly = (props: IProps) => {
    const navigate = useNavigate();
    const user = props.user;
    const { userBloc } = useContext(ServiceBlocContext);

    const renderRow = (label: string, value: any) => {
        return <Grid container sx={{ paddingBottom: '6px' }}>
            <Grid item xs={12}>
                <b>{label}:</b>
            </Grid>
            <Grid item xs={12} >
                {value}
            </Grid>
        </Grid>
    }

    const impersonate = (userId: string, firstName: string = "", lastName: string = "") => {
        log("impersonating user. userId: ", userId);
        userBloc.impersonateUser({
            impersonatedUserId: userId,
            impersonatedUserFirstName: firstName,
            impersonatedUserLastName: lastName,
            impersonatedUser: user
        });
    }

    return (
        <Card sx={{ border: '1px solid #e3f2fd' }}>
            <CardContent>
                {renderRow("ID", <Link href="#" onClick={() => navigate(`/users/edit/${user.id}`)}>
                    {user.displayId}
                </Link>)}
                {renderRow("First Name", user.firstName)}
                {renderRow("Last Name", user.lastName)}
                {renderRow("Email", user.email)}
                {renderRow("Role", user.role)}
                {renderRow("Status", user.isActive ? "Active" : "Inactive")}
                {props.displayActionButtons && <Grid container sx={{ paddingBottom: '2px' }}>
                    <Grid item xs={12}>
                        <Button variant="contained"
                            onClick={() => navigate(`/users/edit/${user.id}`)}
                            startIcon={<EditIcon />}>
                            Edit
                        </Button>
                        <Button variant="contained"
                            sx={{ marginLeft: '5px' }}
                            onClick={() => impersonate(user.id, user.firstName, user.lastName)}>
                            Impersonate
                        </Button>
                    </Grid>
                </Grid>
                }
            </CardContent>
        </Card>
    )
}

export default UserReadOnly;