import React from "react";
import { AppBloc } from "./AppBloc";
import { LendingGroupBloc } from "./LendingGroupBloc";
import { LoanBloc } from "./LoanBloc";
import { TransactionBloc } from "./TransactionBloc";
import { UserBloc } from "./UserBloc";

const lendingGroupBloc = new LendingGroupBloc();
const userBloc = new UserBloc();
const loanBloc = new LoanBloc();
const transactionBloc = new TransactionBloc();
const appBloc = new AppBloc();
export const ServiceBlocContext = React.createContext({ lendingGroupBloc, userBloc, loanBloc, transactionBloc, appBloc });