import { NotificationEvent } from "common/Notification";
import { ILoan, ITransaction, ITransactionSummary, IUser, IUserContext, IUserSummary } from "interfaces/lending-groups";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ApiRequest } from "./ApiRequest";
import log from "./LoggingService";
import { MessagingService } from "./MessagingService";


export class UserBloc {
    private _users: BehaviorSubject<IUser[]>;
    private _users$: Observable<IUser[]>;
    private _user: Subject<IUser>;
    private _user$: Observable<IUser>;
    private _userSummary: Subject<IUserSummary>;
    private _userSummary$: Observable<IUserSummary>;
    private _loggedInUser: Subject<IUser>;
    private _loggedInUser$: Observable<IUser>;
    private _impersonatedUser: Subject<IUserContext>;
    private _impersonatedUser$: Observable<IUserContext>;
    private _borrowedLoans: BehaviorSubject<ILoan[]>;
    private _borrowedLoans$: Observable<ILoan[]>;
    private _lentLoans: BehaviorSubject<ILoan[]>;
    private _lentLoans$: Observable<ILoan[]>;
    private _transactions: BehaviorSubject<ITransaction[]>;
    private _transactions$: Observable<ITransaction[]>;
    private _transactionSummary: Subject<ITransactionSummary>;
    private _transactionSummary$: Observable<ITransactionSummary>;
    private _messagingService: MessagingService;
    private _searchedUsers: BehaviorSubject<IUser[]>;
    private _searchedUsers$: Observable<IUser[]>;

    constructor() {
        this._users = new BehaviorSubject<IUser[]>([]);
        this._users$ = this._users.asObservable();
        this._user = new Subject<IUser>();
        this._user$ = this._user.asObservable();
        this._userSummary = new Subject<IUserSummary>();
        this._userSummary$ = this._userSummary.asObservable();
        this._loggedInUser = new Subject<IUser>();
        this._loggedInUser$ = this._loggedInUser.asObservable();
        this._impersonatedUser = new Subject<IUserContext>();
        this._impersonatedUser$ = this._impersonatedUser.asObservable();
        this._borrowedLoans = new BehaviorSubject<ILoan[]>([]);
        this._borrowedLoans$ = this._borrowedLoans.asObservable();
        this._lentLoans = new BehaviorSubject<ILoan[]>([]);
        this._lentLoans$ = this._lentLoans.asObservable();
        this._transactions = new BehaviorSubject<ITransaction[]>([]);
        this._transactions$ = this._transactions.asObservable();
        this._transactionSummary = new Subject<ITransactionSummary>();
        this._transactionSummary$ = this._transactionSummary.asObservable();
        this._messagingService = MessagingService.getInstance();
        this._searchedUsers = new BehaviorSubject<IUser[]>([]);
        this._searchedUsers$ = this._searchedUsers.asObservable();
        log("UserBloc constructed");
    }

    public get users(): Observable<IUser[]> {
        // observable is exposed instead of BehaviorSubject because .next
        // cannot be called from Observable
        return this._users$;
    }

    public get user(): Observable<IUser> {
        // observable is exposed instead of BehaviorSubject because .next
        // cannot be called from Observable
        return this._user$;
    }

    public get userSummary(): Observable<IUserSummary> {
        return this._userSummary$;
    }

    public get loggedInUser(): Observable<IUser> {
        return this._loggedInUser$;
    }

    public get impersonatedUser(): Observable<IUserContext> {
        return this._impersonatedUser$;
    }

    public get borrowedLoans(): Observable<ILoan[]> {
        return this._borrowedLoans$;
    }

    public get lentLoans(): Observable<ILoan[]> {
        return this._lentLoans$;
    }

    public get transactions(): Observable<ITransaction[]> {
        return this._transactions$;
    }

    // public get transactionSummary(): Observable<ITransactionSummary> {
    //     return this._transactionSummary$;
    // }

    public get searchedUsers(): Observable<IUser[]> {
        return this._searchedUsers$;
    }

    public addUser(user: IUser): void {
        console.log("adding user...", user);
        ApiRequest.getInstance().axios.post(`/users`, user).then(() => {
            this.getUsers();
            this._messagingService.publish(new NotificationEvent({ message: "User added!" }));
        });
    }

    public updateUser(user: IUser): void {
        console.log("update user...", user);
        ApiRequest.getInstance().axios.put(`/users/${user.id}`, user).then(() => {
            this.getUsers();
            this._messagingService.publish(new NotificationEvent({ message: "User updated!" }));
        });
    }

    public deactivateUser(userId: string): void {
        console.log("deactivating user...");
        ApiRequest.getInstance().axios.put(`/users/${userId}/deactivate`, null).then(() => {
            this.getUsers();
            this._messagingService.publish(new NotificationEvent({ message: "User deactivated!" }));
        });
    }

    public activateUser(userId: string): void {
        console.log("activating user...");
        ApiRequest.getInstance().axios.put(`/users/${userId}/activate`, null).then(() => {
            this.getUsers();
            this._messagingService.publish(new NotificationEvent({ message: "User activated!" }));
        });
    }

    public addPayment(fromUserId: string, toUserId: string, paymentAmount: number): void {
        console.log(`adding payment. fromUserId: ${fromUserId} toUserId: ${toUserId} amount: ${paymentAmount}`);
        ApiRequest.getInstance().axios.post(`/transactions/payments`, { fromUserId, toUserId, paymentAmount }).then(() => {
            // this.getTransactionSummary(fromUserId);
            this._messagingService.publish(new NotificationEvent({ message: `Paid $${paymentAmount}` }));
        });
    }

    public getUsers(): void {
        ApiRequest.getInstance().axios.get(`/users/`)
            .then(r => {
                this._users.next(r.data);
            });
    }

    public getUser(userId: string): void {
        ApiRequest.getInstance().axios.get(`/users/${userId}`)
            .then(r => {
                this._user.next(r.data);
            });
    }

    public getUserSummary(userId: string): void {
        ApiRequest.getInstance().axios.get(`/users/${userId}/summary`)
            .then(r => {
                this._userSummary.next(r.data);
            });
    }

    public getLoggedInUser(idpId: string): void {
        log("getting logged in user for idpId: ", idpId);
        ApiRequest.getInstance().axios.get(`/users/idpid/${idpId}`)
            .then(r => {
                this._loggedInUser.next(r.data);
            });
    }

    public impersonateUser(userContext: IUserContext): void {
        log("impersonating user, UserContext: ", userContext);
        this._impersonatedUser.next(userContext);
        this._messagingService.publish(new NotificationEvent({ message: "User Impersonated!" }));
    }

    public resetImpersonation(): void {
        log("resetting user impersonation");
        this._impersonatedUser.next({ impersonatedUserId: "" });
        this._messagingService.publish(new NotificationEvent({ message: "Reset impersonation!" }));
    }

    public getBorrowedLoans(userId: string): void {
        log("getting borrowed loans for user: ", userId);
        ApiRequest.getInstance().axios.get(`users/${userId}/borrowedloans`)
            .then(r => {
                this._borrowedLoans.next(r.data);
            });
    }

    public getLentLoans(userId: string): void {
        log("getting lent loans for user: ", userId);
        ApiRequest.getInstance().axios.get(`users/${userId}/lentloans`)
            .then(r => {
                this._lentLoans.next(r.data);
            });
    }

    public getTransactions(userId: string): void {
        log("getting transactions for user: ", userId);
        ApiRequest.getInstance().axios.get(`users/${userId}/transactions`)
            .then(r => {
                this._transactions.next(r.data);
            });
    }

    // public getTransactionSummary(userId: string): void {
    //     log("getting transaction summary for user: ", userId);
    //     ApiRequest.getInstance().axios.get(`users/${userId}/transactionsummary`)
    //         .then(r => {
    //             this._transactionSummary.next(r.data);
    //         });
    // }

    public activateTransaction(transactionId: string, userId: string): void {
        console.log("activating user...");
        ApiRequest.getInstance().axios.put(`/transactions/${transactionId}/activate`, null).then(() => {
            // this.getTransactionSummary(userId);
            this._messagingService.publish(new NotificationEvent({ message: "Transaction activated!" }));
        });
    }

    public search(searchText: string): void {
        ApiRequest.getInstance().axios.get(`/users/search?searchtext=${searchText}`)
            .then(r => {
                this._searchedUsers.next(r.data);
            });
    }

    public dispose(): void {
        this._users.complete();
        this._user.complete();
        this._borrowedLoans.complete();
    }
}
