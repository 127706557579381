import { NotificationEvent } from "common/Notification";
import { ITransaction, ITransactionSummary, ITransactionType, IUser } from "interfaces/lending-groups";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ApiRequest } from "./ApiRequest";
import log from "./LoggingService";
import { MessagingService } from "./MessagingService";


export class TransactionBloc {
    private _loggedInUser: Subject<IUser>;
    private _loggedInUser$: Observable<IUser>;
    private _transactions: BehaviorSubject<ITransaction[]>;
    private _transactions$: Observable<ITransaction[]>;
    private _transactionSummary: Subject<ITransactionSummary>;
    private _transactionSummary$: Observable<ITransactionSummary>;
    private _transactionTypes: Subject<ITransactionType[]>;
    private _transactionTypes$: Observable<ITransactionType[]>;
    private _messagingService: MessagingService;
    private _searchedTransactions: BehaviorSubject<ITransaction[]>;
    private _searchedTransactions$: Observable<ITransaction[]>;

    constructor() {
        this._loggedInUser = new Subject<IUser>();
        this._loggedInUser$ = this._loggedInUser.asObservable();
        this._transactions = new BehaviorSubject<ITransaction[]>([]);
        this._transactions$ = this._transactions.asObservable();
        this._transactionSummary = new Subject<ITransactionSummary>();
        this._transactionSummary$ = this._transactionSummary.asObservable();
        this._transactionTypes = new Subject<ITransactionType[]>();
        this._transactionTypes$ = this._transactionTypes.asObservable();
        this._messagingService = MessagingService.getInstance();
        this._searchedTransactions = new BehaviorSubject<ITransaction[]>([]);
        this._searchedTransactions$ = this._searchedTransactions.asObservable();
        // log("TransactionBloc constructed");
    }

    public get loggedInUser(): Observable<IUser> {
        // observable is exposed instead of BehaviorSubject because .next
        // cannot be called from Observable
        return this._loggedInUser$;
    }

    public get transactions(): Observable<ITransaction[]> {
        return this._transactions$;
    }

    public get transactionSummary(): Observable<ITransactionSummary> {
        return this._transactionSummary$;
    }

    public get transactionTypes(): Observable<ITransactionType[]> {
        return this._transactionTypes$;
    }

    public get searchedTransactions(): Observable<ITransaction[]> {
        return this._searchedTransactions$;
    }

    public addPayment(fromUserId: string, toUserId: string, paymentAmount: number): void {
        console.log(`adding payment. fromUserId: ${fromUserId} toUserId: ${toUserId} amount: ${paymentAmount}`);
        ApiRequest.getInstance().axios.post(`/transactions/payments`, { fromUserId, toUserId, paymentAmount }).then(() => {
            this.getTransactionSummary(fromUserId);
            this._messagingService.publish(new NotificationEvent({ message: `Paid $${paymentAmount}` }));
        });
    }

    public getLoggedInUser(idpId: string): void {
        log("getting logged in user for idpId: ", idpId);
        ApiRequest.getInstance().axios.get(`/users/idpid/${idpId}`)
            .then(r => {
                this._loggedInUser.next(r.data);
            });
    }

    public getTransactions(userId: string): void {
        log("getting transactions for user: ", userId);
        ApiRequest.getInstance().axios.get(`users/${userId}/transactions`)
            .then(r => {
                this._transactions.next(r.data);
            });
    }

    public getTransactionSummary(userId: string): void {
        log("getting transaction summary for user: ", userId);
        ApiRequest.getInstance().axios.get(`users/${userId}/transactionsummary`)
            .then(r => {
                this._transactionSummary.next(r.data);
            });
    }

    public activateTransaction(transactionId: string, userId: string): void {
        console.log("activating transaction...");
        ApiRequest.getInstance().axios.put(`/transactions/${transactionId}/activate`, null).then(() => {
            this.getTransactionSummary(userId);
            this._messagingService.publish(new NotificationEvent({ message: "Transaction activated!" }));
        });
    }

    public search(userId: string, fromDate: string, toDate: string,
        transactionTypes: number[],
        relatedTo?: string): void {
        ApiRequest.getInstance().axios.post(`/transactions/search`, {
            userId,
            fromDate,
            toDate,
            relatedTo,
            transactionTypeIds: transactionTypes
        }).then(r => {
            this._transactionSummary.next(r.data);
        });
    }

    public getTransactionTypes(): void {
        log("getting transaction types...");
        ApiRequest.getInstance().axios.get(`/lookups/transactiontypes`)
            .then(r => {
                this._transactionTypes.next(r.data);
            });
    }

    // public addPayment(fromUserId: string, toUserId: string, paymentAmount: number): void {
    //     console.log(`adding payment. fromUserId: ${fromUserId} toUserId: ${toUserId} amount: ${paymentAmount}`);
    //     ApiRequest.getInstance().axios.post(`/transactions/payments`, { fromUserId, toUserId, paymentAmount }).then(() => {
    //         // this.getTransactionSummary(fromUserId);
    //         this._messagingService.publish(new NotificationEvent({ message: `Paid $${paymentAmount}` }));
    //     });
    // }

    public dispose(): void {
    }
}
