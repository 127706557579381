import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { Alert, CssBaseline, Snackbar, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useContext, useEffect, useState } from 'react';
import { MessagingService } from 'services/MessagingService';
import { NotificationEvent } from 'common/Notification';
// import { UserImpersonatedEvent } from "common/UserLoggedInEvent";
import { UserContext } from 'common/UserContext';
import { IUserContext } from 'interfaces/lending-groups';
import log from 'services/LoggingService';
import { ServiceBlocContext } from 'services/ServiceBlocContext';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state: any) => state.customization);
    const [notification, setNotification] = useState<NotificationEvent>(new NotificationEvent());
    const messagingService = MessagingService.getInstance();
    const [open, setOpen] = useState(false);
    const [userContext, setUserContext] = useState({} as IUserContext);
    const { userBloc } = useContext(ServiceBlocContext);

    useEffect(() => {
        let notificationSubscription = messagingService.of(NotificationEvent).subscribe(result => {
            console.log(result);
            setOpen(true);
            setNotification(result);
        });
        return () => {
            notificationSubscription.unsubscribe();
        };
    });

    useEffect(() => {
        // let loggedInUserSubscription = messagingService.of(UserLoggedInEvent).subscribe(result => {
        //     log("consuming UserLoggedInEvent. Result: ", result);
        //     setUserContext({
        //         ...userContext,
        //         loggedInUserId: result.user.id,
        //         tempUserId: result.user.id,
        //         loggedInUser: result.user,
        //         tempUser: result.user
        //     });
        // });

        let loggedInUserSubscription = userBloc.loggedInUser.subscribe(result => {
            log("loggedInUser: ", result);
            setUserContext({
                ...userContext,
                loggedInUserId: result.id,
                tempUserId: result.id,
                loggedInUser: result,
                tempUser: result
            });
        });

        return () => {
            loggedInUserSubscription.unsubscribe();
        };
    }, [log, userBloc, userContext]);

    useEffect(() => {
        // let impersonatedUserSubscription = messagingService.of(UserImpersonatedEvent).subscribe(result => {
        //     log("consuming UserImpersonatedEvent. UserId: ", result.impersonatedUserId);
        //     log("UserContext: ", userContext);
        //     setUserContext({
        //         ...userContext,
        //         tempUserId: result.impersonatedUserId ? result.impersonatedUserId : userContext.loggedInUserId,
        //         impersonatedUserId: result.impersonatedUserId,
        //         impersonatedUserFirstName: result.impersonatedUserFirstName,
        //         impersonatedUserLastName: result.impersonatedUserLastName,
        //         tempUser: result.impersonatedUserId ? result.impersonatedUser : userContext.loggedInUser
        //     });
        // });
        let impersonatedUserSubscription = userBloc.impersonatedUser.subscribe(result => {
            log("loggedIn UserContext getting updated: ", userContext);
            log("impersonated UserContext getting updated: ", result);
            setUserContext({
                ...userContext,
                tempUserId: result.impersonatedUserId ? result.impersonatedUserId : userContext.loggedInUserId,
                impersonatedUserId: result.impersonatedUserId,
                impersonatedUserFirstName: result.impersonatedUserFirstName,
                impersonatedUserLastName: result.impersonatedUserLastName,
                tempUser: result.impersonatedUserId ? result.impersonatedUser : userContext.loggedInUser,
                impersonatedLendingGroupDisplayId: result.impersonatedLendingGroupDisplayId
            });
        });
        return () => {
            impersonatedUserSubscription.unsubscribe();
        };
    }, [userBloc, userContext]);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <UserContext.Provider value={userContext}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Alert severity={notification.severity} sx={{ width: '100%' }}>
                            {notification.message}
                        </Alert>
                    </Snackbar>
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </UserContext.Provider>
    );
};

export default App;
