// material-ui
import {
    Avatar,
    Box,
    Button,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Tooltip
} from '@mui/material';

// assets
import { Settings, Logout, LegendToggle } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import log from 'services/LoggingService';
import { ApiRequest } from 'services/ApiRequest';
import { AppContext } from 'common/AppContext';
import { ServiceBlocContext } from 'services/ServiceBlocContext';
import { MessagingService } from "services/MessagingService";
import { IUser } from 'interfaces/lending-groups';
// import { UserLoggedInEvent } from "common/UserLoggedInEvent";

// ==============================|| PROFILE MENU ||============================== //

const LoginMenu = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const appContext = useContext(AppContext);
    const { loginWithRedirect, logout, isAuthenticated, getAccessTokenSilently, user: idpUser } = useAuth0();
    const { userBloc, appBloc } = useContext(ServiceBlocContext);
    const [loggedInUser, setLoggedInUser] = useState({} as IUser);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    useEffect(() => {
        log("is logged in from LoginMenu: ", isAuthenticated);
        if (isAuthenticated) {
            if (!ApiRequest.getInstance().isAxiosInitialized) {
                getAccessTokenSilently().then(token => {
                    log("initializing access with access token: ", token);
                    ApiRequest.getInstance().initAxios(appContext.apiBaseUrl, token);
                    log("idp user", idpUser);
                    if (idpUser && idpUser.sub) {
                        userBloc.getLoggedInUser(idpUser.sub);
                    }
                });
            }
        }
    }, [isAuthenticated, getAccessTokenSilently, appContext.apiBaseUrl,
        idpUser, userBloc]);

    useEffect(() => {
        let usersSubscription = userBloc.loggedInUser.subscribe(s => {
            setLoggedInUser(s);
        });
        return () => {
            usersSubscription.unsubscribe();
        }
    }, [userBloc, appBloc]);

    return (
        <React.Fragment>
            {isAuthenticated &&
                <><Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <Tooltip title="Account settings">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar sx={{ width: 40, height: 40 }}>
                                {loggedInUser && loggedInUser.firstName && loggedInUser.lastName ? 
                                    loggedInUser.firstName[0] + " " + loggedInUser.lastName[0] : "" }</Avatar>
                        </IconButton>
                    </Tooltip>
                </Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >

                        <MenuItem onClick={() => navigate("accountsettings")}>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            Account Settings
                        </MenuItem>
                        <MenuItem onClick={() => logoutWithRedirect()}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </>
            }
            {!isAuthenticated &&
                <Button onClick={() => loginWithRedirect()}>Login</Button>
            }
        </React.Fragment>
    );
};

export default LoginMenu;

