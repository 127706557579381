import { AlertColor } from "@mui/material";

export class NotificationEvent {
    public severity: AlertColor = "success"
    public message: string = ""

    public constructor(init?: Partial<NotificationEvent>) {
        Object.assign(this, init);
    }
}

