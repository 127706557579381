import { Autocomplete, debounce, TextField } from '@mui/material';
import { IUser } from 'interfaces/lending-groups';
import { useContext, useEffect, useState } from 'react';
import log from 'services/LoggingService';
import { ServiceBlocContext } from 'services/ServiceBlocContext';

interface IProps {
    label: string,
    onChange: (value: any) => void,
    initialValue?: IUser,
    error?: boolean,
    helperText?: React.ReactNode;
}

const AutocompleteUsers = (props: IProps) => {
    const { userBloc } = useContext(ServiceBlocContext);
    const [searchedUsers, setSearchedUsers] = useState<IUser[]>([] as IUser[]);
    const [, setUserIdToBeAdded] = useState("");
    const [userToBeAdded, setUserToBeAdded] = useState({} as IUser);

    const onUserInputChange = debounce((_event, value) => {
        if (value) {
            log("onUserInputChange", value);
            userBloc.search(value);
            log("searchedFromUsers: ", searchedUsers);
        }
    }, 1000);

    const onUserChange = (_event: any, value: any) => {
        if (value) {
            log("onToUserChange", value);
            setUserIdToBeAdded(value.id);
            setUserToBeAdded(value);
            props.onChange(value);
        }
    }

    useEffect(() => {
        if (props.initialValue) {
            log("setting the initial value for autocomplete: ", props.initialValue);
            setUserToBeAdded(props.initialValue);
        }
        let searchedUsersSubscription = userBloc.searchedUsers.subscribe(result => {
            setSearchedUsers(result);
        });

        return () => {
            searchedUsersSubscription.unsubscribe();
        }
    }, [userBloc, props.initialValue]);

    return (
        <Autocomplete
            fullWidth={true}
            freeSolo={true}
            autoComplete={true}
            onInputChange={onUserInputChange}
            onChange={onUserChange}
            value={userToBeAdded}
            getOptionLabel={(s) => s != null && (s as IUser).displayId ? `${(s as IUser).displayId} | ${(s as IUser).firstName} ${(s as IUser).lastName}` : ""}
            options={searchedUsers}
            noOptionsText="User not found"
            renderInput={(params) => <TextField {...params}
                variant="standard"
                error={props.error}
                helperText={props.helperText}
                label={props.label} />} />
    );
};

export default AutocompleteUsers;
