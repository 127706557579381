import { Button, FormControl, Grid, InputAdornment, Stack, TextField } from "@mui/material";
import { Controller, useForm } from 'react-hook-form';
import { ILendingGroup } from 'interfaces/lending-groups';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { ServiceBlocContext } from 'services/ServiceBlocContext';

interface IProps {
    lendingGroup?: ILendingGroup
}

const LendingGroup = (props: IProps) => {
    const { register, handleSubmit, reset, formState: { errors },
        control } = useForm();
    const navigate = useNavigate();
    const { lendingGroupBloc } = useContext(ServiceBlocContext);
    const isEdit = props.lendingGroup != null;

    useEffect(() => {
        if (props.lendingGroup)
            reset(props.lendingGroup);
    }, [props.lendingGroup, reset]);

    const cancel = () => {
        navigate('/lendinggroups');
    }

    const save = (data: any) => {
        if (isEdit) {
            console.log("saving ", data);
            lendingGroupBloc.updateLendingGroup(data);
        }
        else {
            console.log("creating ", data);
            lendingGroupBloc.createLendingGroup(data);
            navigate('/lendinggroups');
        }
    }

    return (
        <form onSubmit={handleSubmit(save)}>
            <Grid container spacing={2}>
                {isEdit && <Grid item xs={12}>
                    <b>ID:</b> {props.lendingGroup?.displayId}
                </Grid>}
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="numberOfMonths"
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <TextField type="number" label="Number of Months" variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register(field.name, { required: { value: true, message: "This is required" } })}
                                    error={errors && errors[field.name]!} helperText={errors[field.name] && errors[field.name].message} />
                            </FormControl>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="monthlyPrincipalAmount"
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <TextField
                                    type="number"
                                    label="Monthly Principal Amount"
                                    variant="standard"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                    {...register(field.name, { required: { value: true, message: "This is required" } })}
                                    error={errors[field.name]} helperText={errors[field.name] && errors[field.name]!.message} />
                            </FormControl>}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        control={control}
                        name="minimumInterestRate"
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <TextField
                                    type="number"
                                    label="Min. Interest Rate"
                                    variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    {...register(field.name, { required: { value: true, message: "This is required" } })}
                                    error={errors[field.name]} helperText={errors[field.name] && errors[field.name].message} />
                            </FormControl>}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        control={control}
                        name="maximumInterestRate"
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <TextField
                                    type="number"
                                    label="Max. Interest Rate"
                                    variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    {...register(field.name, { required: { value: true, message: "This is required" } })}
                                    error={errors[field.name]} helperText={errors[field.name] && errors[field.name].message} />
                            </FormControl>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="commissionPercentage"
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <TextField
                                    type="number"
                                    label="Commission Percentage"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    {...register(field.name, { required: { value: true, message: "This is required" } })}
                                    error={errors[field.name]}
                                    helperText={errors[field.name] && errors[field.name].message} />
                            </FormControl>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="referrerCommissionPercentage"
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <TextField
                                    type="number"
                                    label="Referrer Commission Percentage"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    {...register(field.name, { required: { value: true, message: "This is required" } })}
                                    error={errors[field.name]}
                                    helperText={errors[field.name] && errors[field.name].message} />
                            </FormControl>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} justifyContent={'flex-end'}>
                        <Button variant="contained" color="error" onClick={cancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit">Save</Button>
                    </Stack>
                </Grid>
            </Grid>
        </form >
    )
}

export default LendingGroup;