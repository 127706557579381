import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import NewLendingGroup from 'views/lending-groups/NewLendingGroup';
import LendingGroups from 'views/lending-groups/LendingGroups';
import EditLendingGroup from 'views/lending-groups/EditLendingGroup';
import AuctionBids from 'views/lending-groups/AuctionBids';
import Login from 'components/authentication/Login';
import PrivateRoute from 'components/authentication/PrivateRoute';
import NewUser from 'views/users/NewUser';
import Users from 'views/users/Users';
import EditUser from 'views/users/EditUser';
import Loans from 'views/loans/Loans';
import Transactions from 'views/transactions/Transactions';
import Loan from 'views/loans/Loan';
import MyLendingGroups from 'views/lending-groups/MyLendingGroups';
import ActiveAuctions from 'views/lending-groups/ActiveAuctions';
import ApplicationError from 'views/common/ApplicationError';
// import AccountSettings from 'views/common/AccountSettings';

// dashboard routing
const Home = Loadable(lazy(() => import('views/common/Home')));
const AccountSettings = Loadable(lazy(() => import('views/common/AccountSettings')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Home />
            // element: <PrivateRoute><Home /></PrivateRoute>
        }, 
        {
            path: '/dashboard',
            element: <PrivateRoute><Home /></PrivateRoute>
        },
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/lendinggroups/new',
            element: <PrivateRoute><NewLendingGroup /></PrivateRoute>
        },
        {
            path: '/lendinggroups/edit/:lendingGroupId',
            element: <PrivateRoute><EditLendingGroup /></PrivateRoute>
        },
        {
            path: '/lendinggroups/:lendingGroupId/auctions/:auctionId/bids',
            element: <PrivateRoute><AuctionBids /></PrivateRoute>
        },
        {
            path: '/lendinggroups',
            element: <PrivateRoute> <LendingGroups /></PrivateRoute>
        },
        {
            path: '/users/lendinggroups',
            element: <PrivateRoute> <MyLendingGroups /></PrivateRoute>
        },
        {
            path: '/users/activeauctions',
            element: <PrivateRoute> <ActiveAuctions /></PrivateRoute>
        },
        {
            path: '/users/new',
            element: <PrivateRoute> <NewUser /></PrivateRoute>
        },
        {
            path: '/users/edit/:userId',
            element: <PrivateRoute><EditUser /></PrivateRoute>
        },
        {
            path: '/users/loans',
            element: <PrivateRoute><Loans /></PrivateRoute>
        },
        {
            path: '/loans/:loanId',
            element: <PrivateRoute><Loan /></PrivateRoute>
        },
        {
            path: '/transactions',
            element: <PrivateRoute><Transactions /></PrivateRoute>
        },
        {
            path: '/users',
            element: <PrivateRoute permission='Permission.ViewAllUsers'><Users /></PrivateRoute>
        },
        {
            path: '/accountsettings',
            element: <AccountSettings />
        },
        {
            path: '/error',
            element: <ApplicationError />
        }
    ]
};

export default MainRoutes;
