import CardWithTitle from 'components/common/CardWithTitle';
import User from 'components/users/User';


const NewUser = () => {
  return (
    <CardWithTitle title='Invite User'>
        <User />
    </CardWithTitle>
  )
}

export default NewUser;