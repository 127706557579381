import { UserContext } from 'common/UserContext';
import { useContext } from 'react';
import log from 'services/LoggingService';

interface IProps {
    permission: string,
    children: React.ReactNode
}

const HasPermission = (props: IProps) => {
    const { tempUser, loggedInUser } = useContext(UserContext);
    log("tempUser: ", tempUser);
    log("loggedInUser: ", loggedInUser);
    log("permission: ", props.permission);
    let hasPermission = loggedInUser?.permissions?.includes(props.permission);
    log("hasPermission: ", hasPermission);
    return (
        <>{ hasPermission && props.children}</>
    );
};

export default HasPermission;
