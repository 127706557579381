import { Button } from '@mui/material';
import { UserContext } from 'common/UserContext';
import { useContext } from 'react';
import log from 'services/LoggingService';
import { ServiceBlocContext } from 'services/ServiceBlocContext';

const ResetImpersonation = () => {
    const { impersonatedUserId, impersonatedUserFirstName, impersonatedUserLastName } = useContext(UserContext);
    const { userBloc } = useContext(ServiceBlocContext);

    const resetImpersonation = () => {
        log("resetting impersonation...");
        userBloc.resetImpersonation();
    }

    return (
        <>  {impersonatedUserId && <><b>Impersonated: </b>{impersonatedUserFirstName + " " + impersonatedUserLastName}
            <br /> <Button sx={{ marginBottom: '10px', marginTop: '5px' }} variant="contained" onClick={() => resetImpersonation()}>
                Reset Impersonation
            </Button>
            <br /></>}</>
    );
};

export default ResetImpersonation;
