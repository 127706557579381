import { Card, CardContent, InputAdornment, TextField, Button, Grid, Link, Typography } from "@mui/material";
import { UserContext } from "common/UserContext";
import { format } from "date-fns";
import { IActiveAuction, IAuctionBid } from "interfaces/lending-groups";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import log from "services/LoggingService";
import { ServiceBlocContext } from "services/ServiceBlocContext";

interface IProps {
    auction: IActiveAuction,
    lendingGroupId: string,
    isFromActiveAuctions?: boolean,
    lendingGroupDisplayId?: string
}

const AuctionBids = (props: IProps) => {
    const { auction, lendingGroupId, isFromActiveAuctions, lendingGroupDisplayId } = props;
    const { lendingGroupBloc } = useContext(ServiceBlocContext);
    const [auctionBids, setAuctionBids] = useState([] as IAuctionBid[]);
    const [biddedInterestRate, setBiddedInterestRate] = useState(0.0);
    const navigate = useNavigate();
    const { tempUserId } = useContext(UserContext);

    useEffect(() => {
        let auctionBidsSubscription = lendingGroupBloc.auctionBids.subscribe(setAuctionBids);

        return () => {
            auctionBidsSubscription.unsubscribe();
        }
    }, [lendingGroupBloc]);

    useEffect(() => {
        log("id, lendingGroupId, lendingGroupDisplayId", auction.id, lendingGroupId, lendingGroupDisplayId);
        lendingGroupBloc.getAuctionBids(lendingGroupId, auction.id);
    }, [lendingGroupBloc, lendingGroupId, auction]);

    const handleInterestRateChange = (event: any) => {
        setBiddedInterestRate(event.target.value);
    }

    const addBid = () => {
        log("adding bid for tempUserId: ", tempUserId);
        lendingGroupBloc.addAuctionBid(lendingGroupId, auction.id, biddedInterestRate, tempUserId);
        setBiddedInterestRate(0.0);
    }

    const renderRow = (label: string, value: any) => {
        return <Grid container sx={{ paddingBottom: '3px' }}>
            <Grid item xs={12}>
                <b>{label}:</b>
            </Grid>
            <Grid item xs={12} >
                {value}
            </Grid>
        </Grid>
    }

    const selectLendingGroup = (id: string) => {
        navigate(`/lendinggroups/edit/${id}`)
    }

    return (
        <>
            <Card>
                {isFromActiveAuctions && <CardContent sx={{
                    border: '1px solid #e3f2fd'
                }}>
                    <Grid container spacing={2} rowSpacing={2}>
                        <Grid item md={11} xs={9}>
                            <TextField
                                fullWidth={true}
                                onChange={handleInterestRateChange}
                                defaultValue={biddedInterestRate}
                                type="number"
                                label="Interest Rate"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">%</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item md={1} xs={3}>
                            <Button variant="contained" onClick={addBid}>Bid</Button>
                        </Grid>
                    </Grid>
                </CardContent>}
                <CardContent sx={{
                    border: '1px solid #e3f2fd',
                    margin: isFromActiveAuctions ? '15px 0' : '15px'
                }}>
                    <b># of bid(s): {auctionBids.length}</b>
                </CardContent>
                {auctionBids.map((auctionBid, index) => (<CardContent key={index} sx={{
                    border: '1px solid #e3f2fd',
                    margin: isFromActiveAuctions ? '15px 0' : '15px'
                }}>
                    {renderRow("Lending Group ID", <Link href="#"
                        onClick={() => selectLendingGroup(auction.lendingGroupId)}>
                        {lendingGroupDisplayId}
                    </Link>)}
                    {renderRow("Name", auctionBid.firstName + " " + auctionBid.lastName)}
                    {renderRow("Bidded Interest Rate", auctionBid.biddedInterestRate)}
                    {renderRow("Bidded Date", format(new Date(auctionBid.biddedDateTime), 'MM/dd/yyyy HH:mm a'))}
                </CardContent >))}
            </Card>
        </>
    )
}

export default AuctionBids;