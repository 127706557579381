import CardContent from '@mui/material/CardContent';
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Grid, Link } from "@mui/material";
import { useContext, useEffect, useState } from 'react';
import { ILoan } from '../../interfaces/lending-groups';
import { useNavigate, useParams } from 'react-router-dom';
import { ServiceBlocContext } from 'services/ServiceBlocContext';
import UserReadOnly from 'components/users/UserReadOnly';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


const Loan = () => {
  const [loan, setLoan] = useState({} as ILoan);
  const { loanBloc } = useContext(ServiceBlocContext);
  const { loanId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let borrowedLoansSubscription = loanBloc.loan.subscribe(setLoan);

    return () => {
      borrowedLoansSubscription.unsubscribe();
    }
  }, [loanBloc]);

  useEffect(() => {
    loanBloc.getLoan(loanId);
  }, [loanBloc, loanId]);

  const selectLendingGroup = (id: string) => {
    navigate(`/lendinggroups/edit/${id}`)
  }

  const renderRow = (label: string, value: any) => {
    return <>
      <Grid item xs={7}>
        <b>{label}:</b>
      </Grid>
      <Grid item xs={5} sx={{ textAlign: "right" }}>
        {value}
      </Grid>
    </>
  }

  return (
    <>
      <Button sx={{ marginBottom: '10px' }} startIcon={<ArrowBackIosIcon />}
        variant="contained" onClick={() => { navigate('/users/loans') }}>
        Back
      </Button>
      <br />
      <Accordion defaultExpanded={true} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fontSize: 20 }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ padding: '0 20px' }}
        > Loan Details
        </AccordionSummary>
        <AccordionDetails>
          <Card sx={{ border: '1px solid #e3f2fd' }}>
            <CardContent>
              <Grid container rowSpacing={1}>
                {renderRow("ID", loan.displayId)}
                {renderRow("Lending Group ID", <Link href="#"
                  onClick={() => selectLendingGroup(loan.lendingGroupId)}>
                  {loan.lendingGroupDisplayId}
                </Link>)}
                {renderRow("Term in Months", loan.termInMonths)}
                {renderRow("Loan Amount", loan.loanAmount)}
                {renderRow("Current Balance Amount", loan.currentBalanceAmount)}
                {renderRow("Interest Rate", loan.interestRate)}
              </Grid>
            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>

      {loan.borrower && <Accordion defaultExpanded={false} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fontSize: 20 }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ padding: '0 20px' }}
        >  Borrower/Lenders
        </AccordionSummary>
        <AccordionDetails>
          <Card sx={{ border: '1px solid #e3f2fd' }}>
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <b>Borrower: </b> <br />
                  {`${loan.borrower.firstName} ${loan.borrower.lastName}`}
                </Grid>
                <Grid sx={{ paddingTop: '6px' }}>
                  <b>Lenders: </b> <br />
                  {loan.lenders?.map((user, index) =>
                    <>{`${index + 1}. ${user.firstName} ${user.lastName}`} {user.isPaidOff ? " [Paid Off]" : ""} <br /></>)
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>}

      {/* {loan.lenders && <Accordion defaultExpanded={false} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fontSize: 20 }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ padding: '0 20px' }}
        >  Lenders
        </AccordionSummary>
        <AccordionDetails>
          {loan.lenders.map((user, index) =>
            <><UserReadOnly key={index} user={user} /> <br /></>)}
        </AccordionDetails>
      </Accordion>} */}
    </>
  )
}

export default Loan;