import axios, { Axios } from "axios";

export class ApiRequest {

    private static _myInstance: ApiRequest;
    private _axios!: Axios;

    static getInstance() {
        if (!ApiRequest._myInstance) {
            ApiRequest._myInstance = new ApiRequest();
        }

        return this._myInstance;
    }

    get axios() {
        if (!this._axios) {
            throw "Axios not initialized";
        }
        return this._axios;
    }

    get isAxiosInitialized() {
        return !!this._axios;
    }

    public initAxios(baseUrl: string, accessToken: string): void {
        this._axios = axios.create({
            baseURL: baseUrl,
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
    }

}