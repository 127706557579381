import CardContent from '@mui/material/CardContent';
import { Button, Card, Grid, Link, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import { ILendingGroup } from '../../interfaces/lending-groups';
import { useNavigate } from 'react-router-dom';
import { ServiceBlocContext } from 'services/ServiceBlocContext';
import InfoIcon from '@mui/icons-material/Info';
import CardWithTitle from 'components/common/CardWithTitle';
import { UserContext } from 'common/UserContext';

const MyLendingGroups = () => {
  const [lendingGroups, setLendingGroups] = useState([] as ILendingGroup[]);
  const navigate = useNavigate();
  const { lendingGroupBloc } = useContext(ServiceBlocContext);
  const { tempUserId } = useContext(UserContext);

  useEffect(() => {
    let lendingGroupsSubscription = lendingGroupBloc.userLendingGroups.subscribe(setLendingGroups);

    return () => {
      lendingGroupsSubscription.unsubscribe();
    }
  }, [lendingGroupBloc]);

  useEffect(() => {
    if (tempUserId) {
      lendingGroupBloc.getUserLendingGroups(tempUserId);
    }
  }, [lendingGroupBloc, tempUserId]);

  const selectLendingGroup = (id: string) => {
    navigate(`/lendinggroups/edit/${id}`)
  }

  const renderRow = (label: string, value: any) => {
    return <>
      <Grid item xs={9}>
        <b>{label}:</b>
      </Grid>
      <Grid item xs={3} sx={{ textAlign: "right" }}>
        {value}
      </Grid>
    </>
  }

  return (<>
    {<CardWithTitle title='Lending Groups'>
      <Card sx={{
        border: '1px solid #e3f2fd'
      }}>
        <CardContent>
          <b># of lending group(s): {lendingGroups.length}</b>
        </CardContent>
      </Card>
      <br />
      {lendingGroups.map((lendingGroup, index) => (<React.Fragment key={index}><Card key={index} sx={{ border: '1px solid #e3f2fd' }}>
        <CardContent>
          <Grid container rowSpacing={1}>
            {renderRow("ID", <Link href="#"
              onClick={() => selectLendingGroup(lendingGroup.id)}>
              {lendingGroup.displayId}
            </Link>)}
            {renderRow("No. Of Months", lendingGroup.numberOfMonths)}
            {renderRow("Monthly Principal Amount", lendingGroup.monthlyPrincipalAmount)}
            {renderRow("Min. Interest Rate", lendingGroup.minimumInterestRate)}
            {renderRow("Max. Interest Rate", lendingGroup.maximumInterestRate)}
            {renderRow("Commission %", lendingGroup.commissionPercentage)}
            {renderRow("Referrer Commission %", lendingGroup.referrerCommissionPercentage)}
            <Grid item xs={12}>
              <Button key={index} id={"details-" + index} variant="contained" startIcon={<InfoIcon />}
                onClick={() => selectLendingGroup(lendingGroup.id)}>
                Details
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
        <br />
      </React.Fragment>))}
    </CardWithTitle>
    }
  </>)
}

export default MyLendingGroups;