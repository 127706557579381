import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from 'common/UserContext';
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import log from 'services/LoggingService';

interface IProps {
    children: ReactNode,
    permission?: string,
}

const PrivateRoute = (props: IProps) => {
    const { children } = props
    const { isAuthenticated } = useAuth0();
    const { loggedInUser } = useContext(UserContext);
    const location = useLocation();
    const [isRenderChildren, setIsRenderChildren] = useState(isAuthenticated);
    const navigate = useNavigate();
    log("LOCATION: ", location.pathname);

    useEffect(() => {
        log("private route props: ", props.permission);
        log("loggedInUser: ", loggedInUser);
        if (isAuthenticated && props.permission) {
            if (loggedInUser && loggedInUser.permissions?.includes(props.permission)) {
                setIsRenderChildren(true);
                log("setIsRenderChildren: ", true);
            } else {
                navigate(`/error?description=Unauthorized Access`);
            }
        }
        else if (isAuthenticated && !props.permission) {
            setIsRenderChildren(true);
            log("setIsRenderChildren: ", true);
        }
    }, [props.permission])

    return isRenderChildren ? (
        <>{children}</>
    ) : (
        <Navigate
            replace={true}
            to="/login"
            state={{ from: `${location.pathname}` }}
        />
    )
}

export default PrivateRoute