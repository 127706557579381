// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { useContext, useEffect, useState } from 'react';
// import { UserLoggedInEvent } from 'common/UserLoggedInEvent';
import { IUser } from 'interfaces/lending-groups';
import { MessagingService } from 'services/MessagingService';
import menuItems from 'routes/menu-items';
import log from 'services/LoggingService';
import { ServiceBlocContext } from 'services/ServiceBlocContext';

const MenuList = () => {
    const [user, setUser] = useState({} as IUser);
    const messagingService = MessagingService.getInstance();
    const { userBloc } = useContext(ServiceBlocContext);

    useEffect(() => {
        // let loggedInUserSubscription = messagingService.of(UserLoggedInEvent).subscribe(result => {
        //     setUser(result.user);
        // });
        let loggedInUserSubscription = userBloc.loggedInUser.subscribe(result => {
            setUser(result);
        });
        return () => {
            loggedInUserSubscription.unsubscribe();
        };
    });

    const navItems = menuItems.items.map((item) => {
        // log("item: ", item);
        if (user.permissions?.includes(item.requiredPermission)) {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} user={user} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
