import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, MenuItem, CardContent, FormControl, Grid, Modal, Select, Stack, TextField, SelectChangeEvent, Checkbox, ListItemText } from "@mui/material";
import { useContext, useEffect, useState } from 'react';
import { ITransactionSummary, ITransactionType, IUser } from '../../interfaces/lending-groups';
import { ServiceBlocContext } from 'services/ServiceBlocContext';
import { format, subDays } from 'date-fns';
import { UserContext } from 'common/UserContext';
import CardWithTitle from "components/common/CardWithTitle";
import NewPayment from "components/transactions/NewPayment";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import log from "services/LoggingService";
import { Controller, useForm } from "react-hook-form";
import AutocompleteUsers from "components/common/AutocompleteUsers";


const Transactions = () => {
  const [transactionSummary, setTransactionSummary] = useState({} as ITransactionSummary);
  const [transactionTypes, setTransactionTypes] = useState([] as ITransactionType[]);
  const { userBloc, transactionBloc } = useContext(ServiceBlocContext);
  const { tempUserId, tempUser, impersonatedLendingGroupDisplayId } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [transactionTypeIds, setTransactionTypeIds] = useState<number[]>([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searchFromDate, setSearchFromDate] = useState<Dayjs | null>(null);
  const [searchToDate, setSearchToDate] = useState<Dayjs | null>(null);
  const { register, handleSubmit, reset, formState: { errors }, control } = useForm();
  const [userIdToBeSearched, setUserIdToBeSearched] = useState("");
  const [userToBeSearched, setUserToBeSearched] = useState({} as IUser);
  const [lendingGroupDisplayIdToBeSearched, setLendingGroupDisplayIdToBeSearched] = useState("");

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
    marginRight: '50px'
  };

  useEffect(() => {
    let transactionSummarySubscription = transactionBloc.transactionSummary.subscribe(s => {
      setTransactionSummary(s);
    });
    let transactionTypesSubscription = transactionBloc.transactionTypes.subscribe(setTransactionTypes);

    return () => {
      transactionSummarySubscription.unsubscribe();
      transactionTypesSubscription.unsubscribe();
    }
  }, [transactionBloc]);

  const searchTransactions = (data: any) => {
    console.log("searching transactions ", data);
    let fromDate = dayjs(data.fromDate).format("MM/DD/YYYY");
    let toDate = dayjs(data.toDate).format("MM/DD/YYYY");
    log("from date to be searched: ", dayjs(data.fromDate).format("MM/DD/YYYY"));
    log("to date to be searched: ", dayjs(data.toDate).format("MM/DD/YYYY"));
    log("transactionTypes: ", transactionTypeIds);
    if (userIdToBeSearched) {
      log("userIdToBeSearched: ", userIdToBeSearched);
      transactionBloc.search(userIdToBeSearched, fromDate, toDate, transactionTypeIds, lendingGroupDisplayIdToBeSearched,
      );
    }
  }

  useEffect(() => {

    if (impersonatedLendingGroupDisplayId) {
      log("impersonatedLendingGroupDisplayId: ", impersonatedLendingGroupDisplayId);
      setLendingGroupDisplayIdToBeSearched(impersonatedLendingGroupDisplayId);
    }
    else {
      log("impersonatedLendingGroupDisplayId null");
    }

    if (tempUserId && tempUser) {
      log("setting user to be searched: ", tempUser);
      let date = new Date();
      let tempToDate = format(new Date(), "MM/dd/yyyy");
      let tempFromDate = format(subDays(date, 30), "MM/dd/yyyy");
      setUserToBeSearched(tempUser);
      setUserIdToBeSearched(tempUserId);
      let fromDate = dayjs(tempFromDate).format("MM/DD/YYYY");
      let toDate = dayjs(tempToDate).format("MM/DD/YYYY");
      if (tempUserId) {
        transactionBloc.search(tempUserId, fromDate, toDate, transactionTypeIds, impersonatedLendingGroupDisplayId,
        );
      }
    }

    transactionBloc.getTransactionTypes();
  }, [transactionBloc, tempUserId, tempUser, impersonatedLendingGroupDisplayId]);

  useEffect(() => {
    let date = new Date();
    let toDate = format(new Date(), "MM/dd/yyyy");
    let fromDate = format(subDays(date, 30), "MM/dd/yyyy");
    setSearchFromDate(dayjs(fromDate));
    setSearchToDate(dayjs(toDate));
    reset({ fromDate: dayjs(fromDate), toDate: dayjs(toDate) });
  }, [dayjs]);

  // const markPaid = (transactionId: string) => {
  //   if (tempUserId) {
  //     transactionBloc.activateTransaction(transactionId, tempUserId);
  //   }
  // }

  const handleChange = (event: SelectChangeEvent<typeof transactionTypeIds>) => {
    const {
      target: { value },
    } = event;
    setTransactionTypeIds(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',').map(Number) : value,
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const onSearchUserChange = (value: any) => {
    if (value) {
      log("onUserChange", value);
      setUserIdToBeSearched(value.id);
      setUserToBeSearched(value);
    }
  }

  const renderRow2 = (label: string, value: any) => {
    return <Grid container sx={{ paddingBottom: '6px', paddingTop: '6px' }}>
      <Grid item xs={12}>
        <b>{label}:</b>
      </Grid>
      <Grid item xs={12} >
        {value}
      </Grid>
    </Grid>
  }

  const renderRow = (label: string, value: any) => {
    return <>
      <Grid item xs={4}>
        <b>{label}:</b>
      </Grid>
      <Grid item xs={8} sx={{ textAlign: "right" }} >
        {value}
      </Grid>
    </>
  }

  return (<CardWithTitle title="Transactions">
    <>
      <Card sx={{
        border: '1px solid #e3f2fd', marginBottom: '10px'
      }}>
        <CardContent sx={{
          padding: '10px 10px'
        }}>
          <b>Current Balance:</b> {transactionSummary.currentBalanceFormatted} (to be {transactionSummary.currentBalance > 0 ? "received" : "paid"})
          <br />
          <Button sx={{
            marginTop: '10px'
          }}
            onClick={handleOpen} variant="contained">Pay</Button>
          <br />
          <br />

          <form onSubmit={handleSubmit(searchTransactions)}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="fromDate"
                  render={({ field: { onChange, ...restField } }) =>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...restField}
                          label="From"
                          value={searchFromDate}
                          onChange={(newValue) => {
                            onChange(newValue);
                            if (newValue)
                              setSearchFromDate(newValue);
                          }}
                          renderInput={(params) => <TextField {...params}
                            {...register("fromDate")}
                            variant="standard" />} />
                      </LocalizationProvider>
                    </FormControl>}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="toDate"
                  render={({ field: { onChange, ...restField } }) =>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...restField}
                          label="To"
                          value={searchToDate}
                          onChange={(newValue) => {
                            onChange(newValue);
                            if (newValue)
                              setSearchToDate(newValue);
                          }}
                          renderInput={(params) => <TextField {...params}
                            {...register("toDate")}
                            variant="standard" />} />
                      </LocalizationProvider>
                    </FormControl>}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Controller
                  control={control}
                  name="searchUserId"
                  render={({ field }) =>
                    // onchange needs to be fixed in AutocompleteUsers
                    <FormControl fullWidth>
                      <AutocompleteUsers
                        {...register(field.name)}
                        label="User (search by name or ID)"
                        onChange={onSearchUserChange}
                        initialValue={userToBeSearched}
                      />
                    </FormControl>}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="relatedTo"
                  render={({ field }) =>
                    <FormControl fullWidth>
                      <TextField
                        type="string" label="Related To" variant="standard"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event: any) => setLendingGroupDisplayIdToBeSearched(event.target.value)}
                        value={lendingGroupDisplayIdToBeSearched}
                      />
                    </FormControl>}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="transactionTypes"
                  render={({ field }) =>
                    <FormControl fullWidth>
                      <Select
                        {...register(field.name)}
                        label="Transaction Type"
                        variant="standard"
                        value={transactionTypeIds}
                        onChange={handleChange}
                        renderValue={(selected) => {
                          let filtered = transactionTypes.filter(item => selected.includes(item.id));
                          let descriptions = filtered.map(m => m.description);
                          return descriptions.join(', ');
                        }}
                        MenuProps={MenuProps}
                        multiple
                      >
                        {transactionTypes.map((transactionType, index) => (
                          // <MenuItem value={transactionType.id}>{transactionType.description}</MenuItem>
                          <MenuItem key={transactionType.id} value={transactionType.id}>
                            <Checkbox checked={transactionTypeIds.indexOf(transactionType.id) > -1} />
                            <ListItemText primary={transactionType.description} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2} justifyContent={'flex-end'}>
                  {/* <Button variant="contained" color="error" onClick={cancel}>
                    Cancel
                  </Button> */}
                  <Button variant="contained" type="submit">Search</Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      {transactionSummary && transactionSummary.transactions && transactionSummary.transactions.map((transaction, index) => (
        <Accordion defaultExpanded={false} key={index} sx={{ border: '1px solid #e3f2fd', marginBottom: '2px' }}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: '0 5px' }}
          >
            <Grid container sx={{ color: "#939596", ...(transaction.isActive ? { color: "#000" } : {}) }}>
              <Grid item xs={3}>
                {format(new Date(transaction.transactionDate), 'MM/dd/yyyy')}
              </Grid>
              <Grid item xs={5} >
                {transaction.shortDescription}
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'right', paddingRight: '3px', paddingTop: '8px' }}>
                <b>{transaction.transactionAmount}</b>
              </Grid>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={5} sx={{ paddingTop: '8px' }}>
                {/* <i>{transaction.isActive ? (transaction.isCredit ? 'Credit' : 'Debit') : 'Pending'}</i> */}
                <i>{transaction.entityType && `${transaction.entityType} ${transaction.entityDisplayId}`}</i>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'right', paddingRight: '3px', paddingTop: '8px' }}>
                <i>{transaction.isActive ? transaction.balance : ''}</i>
              </Grid>
              {/* {!transaction.isActive && <Grid item xs={12}>
                <Button onClick={() => markPaid(transaction.id)} variant="contained">Activate</Button>
              </Grid>} */}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={1}>
              {renderRow2("Date", format(new Date(transaction.transactionDate), 'MM/dd/yyyy'))}
              {renderRow2("Transaction", transaction.description)}
              {transaction.entityType && renderRow2("Related To", `${transaction.entityType} ${transaction.entityDisplayId}`)}
              {renderRow2("From", transaction.fromUser)}
              {renderRow2("To", transaction.toUser)}
              {renderRow2("Amount", transaction.transactionAmount)}
              {renderRow2("Balance", transaction.balance)}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))
      }

      {transactionSummary && <Grid container sx={{
        padding: '6px', fontWeight: 'bold',
        border: '1px solid #e3f2fd'
      }}>
        <Grid item xs={6} sx={{ textAlign: 'left' }}>
          Count: {transactionSummary.transactions?.length}
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          Total: {transactionSummary.total}
        </Grid>
      </Grid>}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}

          <NewPayment paymentAmount={transactionSummary.currentBalance} handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  </CardWithTitle>
  )
}

export default Transactions;