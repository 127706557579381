import { Autocomplete, Button, debounce, FormControl, Grid, InputAdornment, Stack, TextField } from "@mui/material";
import { Controller, useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import log from "services/LoggingService";
import { UserContext } from "common/UserContext";
import { ServiceBlocContext } from "services/ServiceBlocContext";
import { IUser } from "interfaces/lending-groups";

interface IProps {
    paymentAmount: number,
    handleClose: () => void
}

const NewPayment = (props: IProps) => {
    const { register, handleSubmit, reset, formState: { errors },
        control, setError } = useForm();
    const [toUserId, setToUserId] = useState("");
    const [toUser, setToUser] = useState({} as IUser);
    const [fromUserId, setFromUserId] = useState("");
    const [fromUser, setFromUser] = useState({} as IUser);
    const { tempUser } = useContext(UserContext);
    const { userBloc, transactionBloc } = useContext(ServiceBlocContext);
    const [searchedToUsers, setSearchedToUsers] = useState<IUser[]>([] as IUser[]);
    const [searchedFromUsers, setSearchedFromUsers] = useState<IUser[]>([] as IUser[]);

    useEffect(() => {
        if (props.paymentAmount) {
            log("tempUser: ", tempUser);
            if (tempUser) {
                reset({ paymentAmount: Math.abs(props.paymentAmount), fromUserId: tempUser.id });
            }
            else {
                reset({ paymentAmount: Math.abs(props.paymentAmount) });
            }
        }
        if (tempUser) {
            setFromUser(tempUser);
            setFromUserId(tempUser.id);
        }
    }, [props.paymentAmount, reset, tempUser]);

    useEffect(() => {
        let searchedToUsersSubscription = userBloc.searchedUsers.subscribe(result => {
            setSearchedToUsers(result);
        });

        let searchedFromUsersSubscription = userBloc.searchedUsers.subscribe(result => {
            setSearchedFromUsers(result);
        });

        return () => {
            searchedFromUsersSubscription.unsubscribe();
            searchedToUsersSubscription.unsubscribe();
        }
    }, [userBloc]);

    const cancel = () => {
        props.handleClose();
    }

    const save = (data: any) => {
        log("adding payment ", data);
        if (!fromUserId) {
            setError("fromUserId", { type: "focus", message: "User not found" }, { shouldFocus: true });
            return
        }

        if (!toUserId) {
            setError("toUserId", { type: "focus", message: "User not found" }, { shouldFocus: true });
            return;
        }

        transactionBloc.addPayment(fromUserId, toUserId, data.paymentAmount);
        props.handleClose();
    }

    const onToUserInputChange = debounce((event, value) => {
        if (value) {
            log("onToUserInputChange", value);
            userBloc.search(value);
            log("searchedToUsers: ", searchedToUsers);
        }
    }, 1000);

    const onFromUserInputChange = debounce((event, value) => {
        if (value) {
            log("onFromUserInputChange", value);
            userBloc.search(value);
            log("searchedFromUsers: ", searchedFromUsers);
        }
    }, 1000);

    const onToUserChange = (event: any, value: any) => {
        if (value) {
            log("onToUserChange", value);
            setToUserId(value.id);
            setToUser(value);
        }
    }

    const onFromUserChange = (event: any, value: any) => {
        log("onFromUserChange", value);
        setFromUserId(value?.id);
        setFromUser(value);
    }

    return (
        <form onSubmit={handleSubmit(save)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="fromUserId"
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <Autocomplete
                                    fullWidth={true}
                                    freeSolo={true}
                                    autoComplete={true}
                                    onInputChange={onFromUserInputChange}
                                    value={fromUser}
                                    getOptionLabel={s => s != null && s.displayId ? `${s.displayId} | ${s.firstName} ${s.lastName}` : ""}
                                    options={searchedToUsers}
                                    noOptionsText="User not found"
                                    onChange={onFromUserChange}
                                    renderInput={(params) => <TextField {...params}
                                        variant="standard"
                                        {...register(field.name, { required: { value: true, message: "This is required" } })}
                                        error={errors && errors[field.name]!} helperText={errors[field.name] && errors[field.name].message}
                                        label="Search by name or ID" />} />
                            </FormControl>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="toUserId"
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <Autocomplete
                                    fullWidth={true}
                                    freeSolo={true}
                                    autoComplete={true}
                                    onInputChange={onToUserInputChange}
                                    value={toUser}
                                    getOptionLabel={s => s != null && s.displayId ? `${s.displayId} | ${s.firstName} ${s.lastName}` : ""}
                                    options={searchedToUsers}
                                    noOptionsText="User not found"
                                    onChange={onToUserChange}
                                    renderInput={(params) => <TextField {...params}
                                        variant="standard"
                                        {...register(field.name, { required: { value: true, message: "This is required" } })}
                                        error={errors && errors[field.name]!} helperText={errors[field.name] && errors[field.name].message}
                                        label="Search by name or ID" />} />
                            </FormControl>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="paymentAmount"
                        render={({ field }) =>
                            <FormControl fullWidth>
                                <TextField
                                    type="number"
                                    label="Amount"
                                    variant="standard"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                    {...register(field.name, { required: { value: true, message: "This is required" } })}
                                    error={errors[field.name]} helperText={errors[field.name] && errors[field.name]!.message} />
                            </FormControl>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} justifyContent={'flex-end'}>
                        <Button variant="contained" color="error" onClick={cancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit">Save</Button>
                    </Stack>
                </Grid>
            </Grid>
        </form >
    )
}

export default NewPayment;