import CardWithTitle from 'components/common/CardWithTitle';
import LendingGroup from 'components/lending-groups/LendingGroup';


const NewLendingGroup = () => {
  return (
    <CardWithTitle title='New Lending Group'>
      <LendingGroup />
    </CardWithTitle>
  )
}

export default NewLendingGroup;