import { ILoan } from "interfaces/lending-groups";
import { Observable, Subject } from "rxjs";
import { ApiRequest } from "./ApiRequest";
import log from "./LoggingService";
import { MessagingService } from "./MessagingService";


export class LoanBloc {
    private _loan: Subject<ILoan>;
    private _loan$: Observable<ILoan>;
    private _messagingService: MessagingService;

    constructor() {
        this._loan = new Subject<ILoan>();
        this._loan$ = this._loan.asObservable();
        this._messagingService = MessagingService.getInstance();
        // log("LoanBloc constructed");
    }

    public get loan(): Observable<ILoan> {
        // observable is exposed instead of BehaviorSubject because .next
        // cannot be called from Observable
        return this._loan$;
    }

    public getLoan(loanId: string): void {
        ApiRequest.getInstance().axios.get(`/loans/${loanId}`)
            .then(r => {
                this._loan.next(r.data);
            });
    }

    public dispose(): void {
        this._loan.complete();
    }
}
